import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor( private http: HttpClient ) { }

  forgotEmail;
  // function to check user login or not
  get isUserlogIn(){
    if (localStorage.getItem('auth_info') != null) {
      //authInfo = JSON.parse(localStorage.getItem('auth_info'))
      return true;
    }
    return false;
  }

  // to login into app and set auth true in local strorage
  login(data:any) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/rest/oauth/token`,
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic d2ViX2NsaWVudDokNGEkNTQkaXVqb2l1alhpSE9ORHdyL0tpSVU5MGUzM1Rpb0lLaW9pSXJJbXJPeFRIVkdXdVNzd0d1Vzk='
        }),
        params:data
      }
    ).pipe(
        map(
          data => {
            if (data && data.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                let access_token = data.access_token;
                //localStorage.setItem('key', JSON.stringify(object));
                //JSON.parse(localStorage.getItem('key'));
                localStorage.setItem('auth_info', JSON.stringify({ auth_key: access_token, logged: true }));
            }
            return data;
          }
        )
      );
  }

   // send mail to get password
   forgetpass(data:any) {
     this.forgotEmail = data.email;
    //  console.log(this.forgotEmail);
    return this.http.post<any>(
      environment.carotUrl + `api/rest/users/dealer/generateotp`,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': 'bearer f5c4a0f1-43d1-457e-9478-3fdb6136d3ef'
          'Authorization': 'Basic d2ViX2NsaWVudDokNGEkNTQkaXVqb2l1alhpSE9ORHdyL0tpSVU5MGUzM1Rpb0lLaW9pSXJJbXJPeFRIVkdXdVNzd0d1Vzk='
        })
      }
    );
   }

   changePass(data:any) {
    // console.log(email_id);
    return this.http.post<any>(
      environment.carotUrl + `api/rest/users/forgotpassword`,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': 'bearer 4fd823c3-ab56-468e-9b47-5bd2a5415b9b'
          'Authorization': 'Basic d2ViX2NsaWVudDokNGEkNTQkaXVqb2l1alhpSE9ORHdyL0tpSVU5MGUzM1Rpb0lLaW9pSXJJbXJPeFRIVkdXdVNzd0d1Vzk='
        })
      }
    );
  }

  // to get logged in user detail
  loggedInUser(){
    return this.http.get<any>(`${environment.apiUrl}/api/rest/users/currentuserv2`);
  }


  // to logout from app and reset local strorage
  logout() {
    console.log('logout app : remove auth_info');
    // remove user from local storage to log user out
    localStorage.clear();
  }

  // to get sidebar list
  // to get logged in user detail
  getResourceAccess() {
    return this.http.get<any>(`${environment.apiV2Url}/org`);
  }
  getPermissionsTabs() {
    return this.http.get<any>(`${environment.apiV2Url}/ra/resourceaccess?type=ACTION`);
  }

}
