import { Component, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService, ModalService } from '../../_services';
import { first, filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent{

  pageTitle : string;
  @Input() currUserData: {};
  @Input() homeLoc: string;
  resourceAccessData:any = [];
  resourceAccessList:any = [];
  resourceAccessSort:any = [];
  countReports:number;
  currUserType:{};
  
  constructor(
    private authentication : AuthService,
    private router : Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService 
  ) { 
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        switchMap(route => route.data),
        map((data) => {
          if (data.title) {
            // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
            return data.title;
          } else {
            // If not, we do a little magic on the url to create an approximation
            return ' ';
          }
        })
      )
      .subscribe((pathString) => this.pageTitle  = `${pathString}`);
  }

  ngOnInit() {}

  onlogout(){
    this.authentication.logout();
    this.router.navigate(['/login']);
  }


  openModal(id: string) {
    this.modalService.open(id);
  }

}
