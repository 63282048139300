import { Component, OnInit, Input} from '@angular/core';
import { FormGroup, FormBuilder , Validators } from '@angular/forms';
import { UsersService, ResourceAccessService, ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-kyc-add',
  templateUrl: './kyc-add.component.html',
  styleUrls: ['./kyc-add.component.css']
})
export class KycAddComponent implements OnInit {
  @Input() userAccessData:any = {};
  registerKycForm: FormGroup;
  formData = {};
  docTypes = ['ADHAAR', 'PASSPORT', 'DRIVING_LICENCE'];
  customerTypes = ['INDIVIDUAL', 'CORPORATE'];
  selCustType: string;
  corporateDocTypes = ['ADHAAR', 'PASSSPORT'];
  submitted = false;
  showIndividualTab = false;
  showCorporateTab = false;
  resLoader = false;
  errorMsg: boolean;
  currOrgId: any;
  statusMsg: string;
  individualFile: any;
  fileStatus = 'No File Chosen';
  panFileStatus = 'No File Chosen';
  panFile: any;
  authorisedFileStatus = 'No File Chosen';
  authorisedFile: any;
  individualFileName: any;
  fileErrorMsg = false;
  submitOptional = false;
  filePanMsg = false;
  fileAuthoriseMsg = false;
  orgType: any;

  // frmData = {};
  constructor(
    private formBuilder: FormBuilder,
    private authentication: UsersService,
    private router: Router,
    private resourceAccessService: ResourceAccessService,
    private modalService: ModalService
  ) {
    this.currOrgId = this.resourceAccessService.currOrgId;
  }

  ngOnInit() {
    // console.log(this.currOrgId);
    this.registerKycForm = this.formBuilder.group({
      vehicleMake: ['', Validators.required],
      vehicleModel: ['', Validators.required],
      chasisNumber: ['', Validators.required],
      engineNumber: ['', Validators.required],
      registerationNumber: ['', Validators.required],
      deviceSerialNumber: [''],
      deviceModelNumber: [''],
      // customerType: [this.customerTypes[0], Validators.required],
      customerType: ['', Validators.required],
      customerName: ['', Validators.required],
      customerMobileNumber: ['', Validators.required],
      customerEmail: ['', ],
      customerAddress: ['', Validators.required],
      documentType: ['', ],
      documentNumber: ['', ],
      documentPanNumber: ['', ],
      gstNumber: ['', ],
      authorisedPersonDocumentType: ['', ],
      authorisedPersonDocumentNumber: ['', ],
      individualFile: ['', ],
      panFile: ['', ],
      authorisedFile: ['', ]
      // documentType: ['', Validators.required],
      // documentNumber: ['', Validators.required],
      // documentPanNumber: ['', Validators.required],
      // gstNumber: ['', Validators.required],
      // authorisedPersonDocumentType: ['', Validators.required],
      // authorisedPersonDocumentNumber: ['', Validators.required]
    });

    this.selCustType = this.f.customerType.value.trim();
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerKycForm.controls; }

  onSubmit() {
    // this.successModal()
    this.submitted = true;
    this.submitOptional = true;
    // stop here if form is invalid
    console.log(this.registerKycForm);
    console.log(this.registerKycForm.controls);

    if (this.registerKycForm.invalid) {
      // console.log()
      console.log('return');
      return;
    }
    const input = new FormData();

    if (this.f.customerType.value === 'INDIVIDUAL') {

        this.formData = {
          vehicleMake: this.f.vehicleMake.value.trim(),
          vehicleModel : this.f.vehicleModel.value.trim(),
          chasisNumber : this.f.chasisNumber.value.trim(),
          // vinNumber : this.f.vinNumber.value.trim(),
          engineNumber : this.f.engineNumber.value.trim(),
          registerationNumber : this.f.registerationNumber.value.trim(),
          deviceSerialNumber : this.f.deviceSerialNumber.value,
          deviceModelNumber : this.f.deviceModelNumber.value.trim(),
          customerType : this.f.customerType.value.trim(),
          customerName : this.f.customerName.value.trim(),
          customerMobileNumber : this.f.customerMobileNumber.value.trim(),
          customerEmail : this.f.customerEmail.value.trim(),
          customerAddress : this.f.customerAddress.value.trim(),
          documentType: this.f.documentType.value.trim(),
          documentNumber: this.f.documentNumber.value.trim()
        };
        input.set('documentFile', this.individualFile);
    } else if (this.f.customerType.value === 'CORPORATE') {
        this.formData = {
          vehicleMake: this.f.vehicleMake.value.trim(),
          vehicleModel : this.f.vehicleModel.value.trim(),
          chasisNumber : this.f.chasisNumber.value.trim(),
          // vinNumber : this.f.vinNumber.value.trim(),
          engineNumber : this.f.engineNumber.value.trim(),
          registerationNumber : this.f.registerationNumber.value.trim(),
          deviceSerialNumber : this.f.deviceSerialNumber.value,
          deviceModelNumber : this.f.deviceModelNumber.value.trim(),
          customerType : this.f.customerType.value.trim(),
          customerName : this.f.customerName.value.trim(),
          customerMobileNumber : this.f.customerMobileNumber.value.trim(),
          customerEmail : this.f.customerEmail.value.trim(),
          customerAddress : this.f.customerAddress.value.trim(),
          documentType: this.f.documentType.value.trim(),
          documentNumber: this.f.documentPanNumber.value.trim(),
          gstNumber: this.f.gstNumber.value.trim(),
          authorisedPersonDocumentType: this.f.authorisedPersonDocumentType.value.trim(),
          authorisedPersonDocumentNumber: this.f.authorisedPersonDocumentNumber.value.trim()
        };
        input.set('documentFile', this.panFile);
        input.set('authorizedPersonDocumentFile', this.authorisedFile);

    }

    console.log(this.formData)

    input.append('kyc', JSON.stringify(this.formData));

    this.authentication.createKycData(input)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.resLoader = false;
          this.successModal();
          // this.router.navigate(['/kyc']);
        },
        error => {
          this.resLoader =  false;
          this.errorMsg = true;
          // this.successModal();
          // this.router.navigate(['/kyc']);
          this.statusMsg = error.error.description ? error.error.description : error.error.message;
          this.errorModal();
          console.log(error);
        }
      );
    }
    onCustomerChange() {
      console.log('on change cust type');
      this.submitOptional = false;
      this.showIndividualTab = false;
      this.showCorporateTab = false;
      this.showIndividualTab = this.f.customerType.value === 'INDIVIDUAL';
      this.showCorporateTab = this.f.customerType.value === 'CORPORATE';
      this.f.documentType.setValue(this.showCorporateTab  ? 'PAN_CARD' : 'ADHAAR');

      // this.registerKycForm.controls['documentType'].clearValidators();
      // this.registerKycForm.controls['documentNumber'].clearValidators();
      // this.registerKycForm.controls['individualFile'].clearValidators();

      if (this.showIndividualTab) {
        this.registerKycForm.controls['documentType'].setValidators([Validators.required]);
        this.registerKycForm.controls['documentNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['individualFile'].setValidators([Validators.required]);
        this.registerKycForm.updateValueAndValidity();

      } else if (this.showCorporateTab) {
        this.registerKycForm.controls['documentPanNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['panFile'].setValidators([Validators.required]);
        this.registerKycForm.controls['gstNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedPersonDocumentType'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedPersonDocumentNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedFile'].setValidators([Validators.required]);
        this.registerKycForm.updateValueAndValidity();
      }
    }
    fileUpload(event) {
      this.individualFile = event.target.files[0];
      console.log(this.individualFile);
      this.fileStatus = event.target.files[0].name;
      console.log(this.individualFile.type);
      if (this.individualFile.type === 'application/pdf') {
        this.fileErrorMsg = false;
      } else {
        this.fileErrorMsg = true;
      }
    }
    panFileUpload(event) {
      this.panFile = event.target.files[0];
      this.panFileStatus = this.panFile.name;
      if (this.panFile.type === 'application/pdf') {
        this.filePanMsg = false;
      } else {
        this.filePanMsg = true;
      }
    }
    authorisedFileUpload(event) {
      this.authorisedFile = event.target.files[0];
      this.authorisedFileStatus = this.authorisedFile.name;
      if (this.authorisedFile.type === 'application/pdf') {
        this.fileAuthoriseMsg = false;
      } else {
        this.fileAuthoriseMsg = true;
      }
    }
    successModal() {
      this.modalService.open('successModal');

    }

    closesuccessModal() {
      this.modalService.close('successModal');

    }

    errorModal() {
      this.modalService.open('errorModal');

    }

    closeerrorModal() {
      this.modalService.close('errorModal');

    }
}
