import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FotaService } from './../_services/fota.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fota-details',
  templateUrl: './fota-details.component.html',
  styleUrls: ['./fota-details.component.css']
})
export class FotaDetailsComponent implements OnInit {

  fotaDetails = [];
  resLoader: boolean;
  groupCode: {};
  id: any;

  currentPage = 1;
  offset = 0;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;

  itemsPerPage: any;
  size: any;

  constructor(
    private fotaService: FotaService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.id = params.uuid;
    });
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page
    this.getFotaDevicesDetail(this.id);
  }

  back() {
    this.location.back();
  }
  private getFotaDevicesDetail(id) {
    this.resLoader = true;
    const formData = {
      offset: this.offset,
      size: this.itemsPerPage
    };

    this.fotaService.getFotaDevicesDetail(id, formData)
      .pipe(first())
      .subscribe(
        data => {
          this.totalItems = data.count;
          this.fotaDetails = data.deviceRequests;
          this.resLoader = false;
        },
        error => {
          this.resLoader = false;
          console.log(error);
        }
      );
  }

  // trigger when user change page
  onPageChange(page: number) {
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.offset = this.offset * this.itemsPerPage;
    this.fotaDetails = [];

    this.getFotaDevicesDetail( this.id); // API call
  }
}
