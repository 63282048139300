import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService, ResourceAccessService, ModalService } from './../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-view-dealer',
  templateUrl: './view-dealer.component.html',
  styleUrls: ['./view-dealer.component.css']
})
export class ViewDealerComponent implements OnInit {
  // on init
  @ViewChild('selectedFile') selectedFileEl:any;
  rightVwHeight: number;
  userTblHeight: number;
  orgList:any = [];
  currOrgName:string;
  currOrgId:any;
  userRoleFilterForm: FormGroup;
  userLoginIdFilterForm: FormGroup;
  itemsPerPage: number;
  currentPage: number = 1;
  offset:number = 0;
  paramData:any = {};
  usersData:any = [];
  resLoader:boolean;
  currUserId:string;
  currUserType:string;
  disableBtn:boolean = false;
  disableActionBtn:boolean = false;

  resLoaderPopup:boolean = false;
  statusMsg:string;
  successMsg:boolean = false;
  errorMsg:boolean = false;

  showFileStatus:boolean = false;
  uploadFileStatus:boolean = false;

  selectedOrgId: string;
  selectedOrgStatusMsg:string = '';
  selectedOrgStatusFlag:boolean = true;

  selectDealer:any;

  constructor(
    private router : Router,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private usersService : UsersService,
    private resourceAccessService : ResourceAccessService
  ) {
    // this.currUrl = this.router.url;
    this.orgList = this.resourceAccessService.orgList;
    this.currOrgId = this.resourceAccessService.currOrgId;
    this.currOrgName = this.resourceAccessService.currOrgName;
  }

  ngOnInit() {
    this.resLoader = true;
    this.userRoleFilterForm = this.formBuilder.group({
      userRole: this.currOrgId
    });
    this.userLoginIdFilterForm = this.formBuilder.group({
      userLoginId : ['']
    });
    this.onUserRoleFilter();
  }

  // Getters : convenience getter for easy access to form fields
  get f() {  return this.userRoleFilterForm.controls; }
  get f2() { return this.userLoginIdFilterForm.controls; }

  // To set parameters for API calling
  private createParamObj(){
    this.resourceAccessService.currOrgId = this.currOrgId;
    this.paramData.orgId = this.currOrgId; // param 1
    /* param 2 */
    if(this.f2.userLoginId.value != '' && this.f2.userLoginId.value != null){
      this.paramData.code =  this.f2.userLoginId.value != '' ? this.f2.userLoginId.value.replace(/^\s+|\s+$/gm,'') : null;
    }
    else if(this.paramData.code){
      delete this.paramData.code;
    }

    this.paramData.offset = this.offset * this.itemsPerPage; // param 3
    this.paramData.size = this.itemsPerPage; // param 4
    return this.paramData;
  }

  // to call user list API
  private loadUsers(param:object){
    this.usersService.getUsersList(param)
      .pipe(first())
      .subscribe(
        users => {
          this.resLoader = false;
          this.usersData = [];
          this.usersData = users;
          // this.usersData = users.users;
          // this.usersDataCount = users.count;
          // console.log(this.usersDataCount);
        },
        error => {
          if(error){
            this.statusMsg = error.error.description ? error.error.description : error.error.message;
            this.errorMsg = true;
            this.errorModal();
          }
          this.resLoader = false;
        }
      );
  }
  // trigger on page load and when user set filter by ROLE
  onUserRoleFilter() {
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight-185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight/38)-1; // calculate size of user list paer page
    this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;
    // reset paggination value
    this.currentPage = 1;
    this.offset = 0;
    // console.log('usersDataCount'+this.usersDataCount+',itemsPerPage'+this.itemsPerPage+',currentPage'+this.currentPage+',offset'+this.offset);
    this.paramData = this.createParamObj();// function calling to set API param
    //this.usersData = []; // reset user list

    this.loadUsers(this.paramData); // API call
  }

  // trigger when user set filter by USER Name/ID
  onUserLoginIdFilter() {
    this.toResetPageSize();
    this.resLoader = true;
    // reset paggination value
    this.currentPage = 1;
    this.offset = 0;

    this.paramData = this.createParamObj(); // function calling to set API param
    this.usersData = [];
    this.loadUsers(this.paramData); // API call
  }

  // trigger when user change page
  onPageChange(page: number) {
    this.toResetPageSize();
    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page == 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.paramData = this.createParamObj();
    this.loadUsers(this.paramData); // API call
  }

  onDownloadSampleExcel(){
    let link = document.createElement("a");
        link.download = "dealer-sample.xlsx";
        link.href = "assets/img/dealers-sample.xlsx";
        link.click();
  }

  openPopup(popupId:string, userId:any){
    console.log(popupId);
    if(popupId == 'uploadFilePopup'){
      this.selectedOrgId = '';
      this.modalService.open(popupId);
    }
    else if(popupId == 'delUserPopup'){
      this.selectDealer = userId;
      this.modalService.open(popupId);
    }
  }

  // To open delete user popup
  closePopup(popupId:any) {
    this.disableBtn = false;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;
    this.selectedOrgId = '';
    this.modalService.close(popupId);
  }

  onChangeOrg(event:any){
    console.log(event);
  }

  onUploadDealer(popppId:string){
    if(this.selectedOrgId){
      this.statusMsg = '';
      this.successMsg = false;
      this.errorMsg = false;
      if (this.selectedFileEl.nativeElement.files.length > 0) {
        this.uploadFileStatus = true;
        let formData = new FormData();
        // formData.append('file', this.selectedFileEl.nativeElement.files[0]['name']);
        formData.append('file', this.selectedFileEl.nativeElement.files[0], this.selectedFileEl.nativeElement.files[0].name);
        this.usersService.uploadDealers(formData, this.selectedOrgId).subscribe(
          users => {
            this.statusMsg = 'File uploaded successfully';
            this.successMsg = true;
            this.errorMsg = false;
            setTimeout(()=>{
              // reset local storage
              this.statusMsg = '';
              this.modalService.close(popppId);
              this.currOrgId = this.selectedOrgId;
              this.offset = 0;
              this.paramData = this.createParamObj();
              this.loadUsers(this.paramData); // API call

            },2000);
          },
          error => {
            console.log(error);
            this.successMsg = false;
            this.errorMsg = true;
            if(error){
              this.statusMsg = this.statusMsg = error.error.description ? error.error.description : error.error.message;
              this.modalService.close(popppId);

            this.errorModal();

            }
            else{
              this.statusMsg = 'Something went wrong, please try again';
            this.errorModal();

            }
            setTimeout(()=>{
              // reset local storage
              this.statusMsg = '';
              this.currOrgId = this.selectedOrgId;
              this.offset = 0;
              this.paramData = this.createParamObj();
              this.loadUsers(this.paramData); // API call
              this.closeerrorModal();
            },2000);
          }
        );
      }
      else{
        this.statusMsg = 'Please choose file, you want to upload';
        this.successMsg = false;
        this.errorMsg = true;
      }
    }
    else{
      this.statusMsg = 'Please choose dealer';
      this.successMsg = false;
      this.errorMsg = true;
    }
  }

  // To close delete user popup
  onDeleteDealer(popupId:any) {
    this.disableActionBtn = false;
    this.disableBtn = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;

    this.usersService.deleteDealerApi(this.selectDealer)
      .pipe(first())
      .subscribe(
        res => {
          //console.log(res);
          this.statusMsg = 'User successfully deleted';
          this.successMsg = true;
          this.errorMsg = false;
          // Need to use ArrowFunction ()=> to preserve this context within setTimeout.
          setTimeout(()=>{
            // reset local storage
            this.statusMsg = '';
            this.modalService.close(popupId);
            this.onUserRoleFilter();
          },2000);
        },
        error => {
          console.log(error);
          this.successMsg = false;
          this.errorMsg = true;
          this.disableBtn = false;
          if(error){
            this.statusMsg = this.statusMsg = error.error.description ? error.error.description : error.error.message;
            this.errorModal();
          }
          else{
            this.statusMsg = 'Something went wrong, please try again';
            this.errorModal();
          }
          // Need to use ArrowFunction ()=> to preserve this context within setTimeout.
          setTimeout(()=>{
            // reset local storage
            this.statusMsg = '';
            this.modalService.close(popupId);
            this.onUserRoleFilter();
            this.closeerrorModal();

          },2000);

        }
      );
  }

  onSelectDealer(){
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = true;
  }

  toResetPageSize(){
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    console.log(this.rightVwHeight);
    this.userTblHeight = this.rightVwHeight-185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight/38)-1; // calculate size of user list paer page
    //this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;
  }

  errorModal() {
    this.modalService.open('errorModal');

  }

  closeerrorModal() {
    this.modalService.close('errorModal');

  }

}

