import { Component, OnInit } from '@angular/core';
import { UsersService, ResourceAccessService, ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { WindowRef } from './../_helpers/window-ref';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.css']
})
export class KycDetailsComponent implements OnInit {
  rightVwHeight: number;
  userTblHeight: number;
  resLoader = false;
  errorMsg: boolean;
  statusMsg: string;
  kycListData = [];
  currOrgId: any;
  dealerCode: any;
  fromDate: any;
  toDate: any;
  minDate: any;
  maxDate: any;
  maxDateLimit: any;
  dateErrorFlag = false;
  userLoginIdFilterForm: FormGroup;
  userRoleList: any = [];
  orgList: any = [];
  userRoleFilterForm: FormGroup;
  itemsPerPage: number;
  currentPage = 1;
  totalItems = 5;
  offset = 0;
  paramData: any;
  formData: any = {};
  orgType: any;
  toggleInput = true;
  dataObj;
  activeChassis;
  kycStatus = '';
  rejectReason;
  errorFeedBack = false;
  constructor(
    private authentication: UsersService,
    private router: Router,
    private winRef: WindowRef,
    private formBuilder: FormBuilder,
    private resourceAccessService: ResourceAccessService,
    private modalService: ModalService
  ) {
    this.currOrgId = this.resourceAccessService.currOrgId;
    this.orgList = this.resourceAccessService.orgList;
    this.minDate = new Date();
    this.minDate = this.minDate.setHours(0, 0, 0);
    this.fromDate = new Date();
    this.maxDate = new Date();
    this.maxDate = this.maxDate.setHours(23, 59, 59);
    this.toDate = new Date();
    this.maxDateLimit = new Date(Date.now());
    this.orgType = this.resourceAccessService.orgType;

  }

  ngOnInit() {
    this.currentPage = 1;
    this.formData.offset = 0;
    this.userRoleList = this.resourceAccessService.orgList;
    console.log(this.userRoleList);
    this.userLoginIdFilterForm = this.formBuilder.group({
      userLoginId: ['']
    });
    this.userRoleFilterForm = this.formBuilder.group({
      userRole: this.currOrgId
    });
    if (this.currOrgId && this.userRoleList[0]['memberType'] !== 'OWNER') {
      this.fnDealerDetail();
    } else {
      this.getKycList();
    }
  }

  errorInput() {
    this.toggleInput = false;
  }

  get f() { return this.userRoleFilterForm.controls; }
  get f2() { return this.userLoginIdFilterForm.controls; }

  onUserRoleFilter(value) {
    this.currOrgId = value;
    this.getKycList();
  }

  onUserLoginIdFilter() {
    this.dealerCode = this.userLoginIdFilterForm.controls.userLoginId.value;
    console.log(this.dealerCode);
    this.getKycList();
  }
  private fnDealerDetail() {
    this.authentication.getDealerDetail(this.currOrgId)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.length > 0) {
            this.dealerCode = data[0].code;
            console.log(this.dealerCode);
            this.getKycList();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  // this.getKycList();

  public getKycList() {
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 1;
    // this.paramData.actionGroup = this.selecttoedRole;
    // this.resourceAccessService.selectedRole = this.selectedRole;
    this.formData.offset = this.offset * this.itemsPerPage;
    this.formData.limit = this.itemsPerPage;


    let formatFromDate: any, formatToDate: any;
    if (!this.fromDate || !this.toDate) {
      this.fromDate = new Date();
      this.fromDate = this.fromDate.setHours(0, 0, 0);
      this.toDate = new Date();
      this.toDate = this.toDate.setHours(23, 59, 59);
    } else {
      formatFromDate = this.fromDate;
      formatToDate = this.toDate;
      formatFromDate = formatFromDate.setHours(0, 0, 0);
      formatToDate = formatToDate.setHours(23, 59, 59);
    }
    if (formatFromDate > formatToDate) {
      this.dateErrorFlag = true;
      // this.orderData = [];
      return;
    } else {
      this.dateErrorFlag = false;
    }

    this.dealerCode = this.userLoginIdFilterForm.controls.userLoginId.value.trim();

    if (this.dealerCode) {
      this.formData = {
        orgId: this.currOrgId,
        dealerCode: this.dealerCode,
        startTime: formatFromDate * 1,
        endTime: formatToDate * 1,
        offset: this.formData.offset,
        limit: this.formData.limit
      };
    } else {
      this.formData = {
        orgId: this.currOrgId,
        startTime: formatFromDate * 1,
        endTime: formatToDate * 1,
        offset: this.formData.offset,
        limit: this.formData.limit
      };
    }
    console.log(this.kycStatus)

    if (this.kycStatus != '' && this.kycStatus != null) {
      this.formData['kycStage'] = this.kycStatus;
    }

    console.log(this.formData);
    this.authentication.getKycList(this.formData)
      .pipe(first())
      .subscribe(
        data => {
          this.resLoader = false;
          this.kycListData = data;
        },
        error => {
          this.resLoader = false;
          this.errorMsg = true;
          this.statusMsg = error.error.description ? error.error.description : error.error.message;
          console.log(error);
        }
      );
  }

  onDateFilter() {
    this.getKycList(); // API call
  }

  changeFunction() {
    this.getKycList();
  }

  onPageChange(page: number) {
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    // console.log(this.rightVwHeight);
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page
    this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

    this.resLoader = true;
    this.currentPage = page;
    this.offset = page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.formData.offset = this.offset * this.itemsPerPage;
    console.log(this.formData);
    // this.paramData = this.createParamObj();
    this.kycListData = [];
    this.getKycList(); // API call

    // this.loadUsers(this.paramData); // API call
  }
  // get all organizations


  downloadExcel() {
    const data = {
      orgId: this.currOrgId,
      dealerCode: this.dealerCode
    };
    const access_token = localStorage.getItem('auth_info');
    const parsedObject = JSON.parse(access_token);
    const res = parsedObject.auth_key;
    let url;
    url = `${environment.apiV2Url}` + '/kyc/download?' +
      // tslint:disable-next-line:max-line-length
      '&orgId=' + data.orgId + '&dealerCode=' + data.dealerCode + '&access_token=' + res + '';
    this.winRef.nativeWindow.open(url, '_blank');
  }

  downloadDoc(id) {
    const paramId = id;
    const access_token = localStorage.getItem('auth_info');
    const parsedObject = JSON.parse(access_token);
    const res = parsedObject.auth_key;
    let url;
    url = `${environment.apiV2Url}` + '/kyc/pdf/' + paramId + '?&access_token=' + res + '';
    this.winRef.nativeWindow.open(url, '_blank');
  }

  UserErrorModal() {
    this.modalService.open('UserErrorModal');

  }

  closeUserErrorModal() {
    this.errorFeedBack = false;
    this.toggleInput = true;
    this.modalService.close('UserErrorModal');


  }

  kycError() {
    this.modalService.open('kycError');
  }

  closekycError() {
    // this.toggleInput = true;
    this.modalService.close('kycError');

  }

  clickedChassisNo(val) {
    console.log(val);
    this.activeChassis = val
  }

  async approveKyc() {
    this.dataObj = {
      chassisNo: this.activeChassis,
      kycStage: 'APPROVED'
    }

    console.log(this.dataObj)
    try {

      const res: any = await this.authentication.approveKyc(this.dataObj);
      if (res == true) {
      this.getKycList()

      }

    } catch (error) {
      this.statusMsg = error.error.description ? error.error.description : error.error.message;
      this.kycError();
    }
  }

  async RejectKYC() {
    let dataObj
    if (this.rejectReason == '' || this.rejectReason == null) {
      this.errorFeedBack = true;
    } else {
      this.closeUserErrorModal();
      this.errorFeedBack = false;
      dataObj = {
        chassisNo: this.activeChassis,
        kycStage: 'REJECTED',
        comment: this.rejectReason
      }

      try {

        const res: any = await this.authentication.approveKyc(dataObj);
        if (res == true) {
          this.getKycList()

          }

      } catch (error) {
        this.statusMsg = error.error.description ? error.error.description : error.error.message;
        this.kycError();
      }

    this.rejectReason = ''

    }



  }

  errorFalse() {
    this.errorFeedBack = false;
    // console.log("val")
  }

}
