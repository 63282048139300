import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResourceAccessService {

  constructor() { }
  public homePath: string= '';

  public orgList:any = [];
  public currOrgId:string;
  public currOrgName:string;

  public orderStatusList:any = [
    {"id": "order_placed", "name": "Order Placed"},
    {"id": "order_accepted", "name": "Order Accepted"},
    {"id": "invoice_generated", "name": "Invoice Generated"},
    {"id": "order_dispatched", "name": "Order Dispatched"},
    {"id": "order_completed", "name": "Order Completed"},
  ];
  public orderStatus:string = 'order_placed';

  public currDepttId: number;
  public orgType: string;
  public orgTitle: string;

}
