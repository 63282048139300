import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TitleService } from './_services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dealer-portal';

  constructor(private chTitle: Title, router: Router) {

    router.events.subscribe((el: NavigationEnd) => {
      var title = this.getTitle(router.routerState, router.routerState.root).join('-');
      // console.log('title', title);
      chTitle.setTitle(title+' - Protal');
    });

  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
