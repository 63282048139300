import { Component, OnInit,OnDestroy,Input } from '@angular/core';
import { ModalService, UsersService } from '../_services';
import { FormBuilder, FormControl, FormGroup ,Validators} from '@angular/forms';
import { RegisterUserService } from '../_services/register-user.service';
import {  ResourceAccessService } from '../_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-vehicle',
  templateUrl: './register-vehicle.component.html',
  styleUrls: ['./register-vehicle.component.css']
})
export class RegisterVehicleComponent implements OnInit, OnDestroy {
  @Input() userAccessData:any = {};
  registerKycForm: FormGroup;
  formData = {};
  docTypes = ['ADHAAR', 'PASSPORT', 'DRIVING_LICENCE'];
  customerTypes = ['INDIVIDUAL', 'CORPORATE'];
  selCustType: string;
  corporateDocTypes = ['ADHAAR', 'PASSSPORT'];
  showIndividualTab = false;
  showCorporateTab = false;
  resLoader = false;
  currOrgId: any;
  individualFile: any;
  individualBackFile: any;
  fileStatus = 'No File Chosen';
  fileStatus2 = 'No File Chosen';
  panFileStatus = 'No File Chosen';
  panFile: any;
  authorisedFileStatus = 'No File Chosen';
  authorisedFile: any;
  individualFileName: any;
  individualBackFileName: any;
  fileErrorMsg = false;
  fileErrorMsg2 = false;
  submitOptional = false;
  filePanMsg = false;
  fileAuthoriseMsg = false;
  registerForm: FormGroup;
  otpForm: FormGroup;

  dataObj;
  otpObj;
  timerVar = 60;
  intervalId;
  timerEnd = true;
  submitted = false;
  submittedRegister = false
  todayDate = new Date;
  statusMsg: string;
  successMsg: boolean;
  errorMsg: boolean;
  btnflag = false;
  formatted_date;
  months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  firstApi = false ;



  constructor(private router: Router,private authentication: UsersService,private modalService: ModalService, private regisService: RegisterUserService, private fb: FormBuilder, private resourceAccessService: ResourceAccessService) {
    this.currOrgId = this.resourceAccessService.currOrgId;
    // console.log(this.f.ais140.value)

    }

  ngOnInit() {
    this.registerKycForm = this.fb.group({
      firstName: ['', [Validators.required] ],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.maxLength(100),
        Validators.pattern(/^\b(([a-zA-Z\-0-9_.]+(\.[a-zA-Z\-0-9_.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\b$/)]],
      mobileNo: ['', [Validators.required,Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^(\+?(\d{1}|\d{2}|\d{3})[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/)]],
      vehicleName: ['', [Validators.required]],
      dealerCode: [''],
      ivoiceDate: [''],
      vehicleMake: ['', Validators.required],
      vehicleModel: ['', Validators.required],
      // chasisNumber: ['', Validators.required],
      engineNumber: ['', Validators.required],
      registerationNumber: ['', Validators.required],
      deviceSerialNumber: [''],
      deviceModelNumber: [''],
      customerType: ['', Validators.required],
      // customerName: ['', Validators.required],
      // customerMobileNumber: ['', Validators.required],
      customerEmail: ['', ],
      address: ['',Validators.required],
      documentType: ['',Validators.required ],
      documentNumber: ['',Validators.required ],
      documentPanNumber: ['', ],
      gstNumber: ['', ],
      authorisedPersonDocumentType: ['', ],
      authorisedPersonDocumentNumber: ['', ],
      individualFile: ['',Validators.required ],
      individualBackFile: ['' ],
      panFile: ['', ],
      authorisedFile: ['',],
      ais140: ['', Validators.required]


    });

    this.selCustType = this.f.customerType.value.trim();
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required] ],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.maxLength(100),
        Validators.pattern(/^\b(([a-zA-Z\-0-9_.]+(\.[a-zA-Z\-0-9_.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\b$/)]],
      mobileNo: ['', [Validators.required,Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^(\+?(\d{1}|\d{2}|\d{3})[- ]?)?\d{3}[- ]?\d{3}[- ]?\d{4}$/)]],
      vehicleName: ['', [Validators.required]],
      dealerCode: [''],
      ivoiceDate: ['']
    });

    this.otpForm = this.fb.group({
      otp:['']
    })
   this.formatted_date = this.todayDate.getDate() + "-" + this.months[this.todayDate.getMonth()] + "-" + this.todayDate.getFullYear()
    this.f.ivoiceDate.patchValue(this.formatted_date);
    console.log(this.f.ais140.value)
  }

  ngOnDestroy() {
    clearInterval(this.intervalId)
  }


  get f() { return this.registerKycForm.controls; }


  get enterOtp () {
    return this.otpForm.controls
  }

  EditRegion() {
    this.modalService.open('enterOtp');
    // console.log(this.modalService.open('editRegion'))
  }

  successModal() {
    this.modalService.open('successModal');

  }

  closesuccessModal() {
    this.modalService.close('successModal');

  }

  closeEditRegion() {
    // this.editRegionForm.value.name = null;
    this.modalService.close('enterOtp');

    // console.log(this.EditRegion())


  }

  kycErrorModal() {
    this.modalService.open('kycErrorModal');

  }

  closekycErrorModal() {
    this.modalService.close('kycErrorModal');

  }

  UserErrorModal() {
    this.modalService.open('UserErrorModal');

  }

  closeUserErrorModal() {
    this.modalService.close('UserErrorModal');

  }

  fieldsChange(val) {
    console.log(val.currentTarget.checked)
    console.log(this.f.ais140.value)

  }

  async createUser() {
    this.btnflag = true;
    // if (this.firstApi == true) {
      this.submittedRegister = true;
    console.log(this.todayDate);
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;

    try {
      // if (this.registerForm.valid) {
        // this.EditRegion();
      this.dataObj = {
        fname: this.f.firstName.value.trim(),
        lname: this.f.lastName.value.trim(),
        email: this.f.email.value.trim(),
        mobileNumber: this.f.mobileNo.value.trim(),
        vehicleUIN: this.f.vehicleName.value.trim(),
        // dealerCode: this.f.dealerCode.value.trim(),
        invoiceDate: +this.todayDate
      }
      const res: any = await this.regisService.createUser(this.dataObj);

      this.successModal();
      this.submitted = false;
      this.btnflag = false;
         this.registerKycForm.reset();
      this.fileStatus = 'No File Chosen';
      this.panFileStatus = 'No File Chosen';
        // console.log(res.status)
      // console.log(this.dataObj)
      // console.log(res);
      // this.intervalId = setInterval(() => {
      //   this.timerVar--;
      //   // console.log(this.timerVar)
      //   if (this.timerVar == 0) {
      //     clearInterval(this.intervalId);
      //     this.timerEnd = false;


      //   }
      //   console.log('timer',this.timerVar)
      // }, 1000)
    // }
    } catch (err) {
      console.log(err.status)
      this.btnflag = false;
      this.errorMsg = true;
      this.successMsg = false;
      this.statusMsg = err.error.description ? err.error.description : err.error.message;
      this.UserErrorModal();

     console.log(err)
   }
  //  }
  }



  async submitOtp() {


    try {
      clearInterval(this.intervalId);

      const res: any = await this.regisService.resendOtp(this.dataObj);
      this.closeEditRegion();

      this.successModal();
      console.log(this.otpObj)

    } catch (error) {
      console.log(error)
    }
  }



  onSubmit() {
    // this.successModal();
    this.btnflag = true;
    // if (this.firstApi == false) {
      console.log(this.firstApi);
      this.submitted = true;
      this.submitOptional = true;
      // stop here if form is invalid
      console.log(this.registerKycForm);
      console.log(this.registerKycForm.controls);

      if (this.registerKycForm.invalid) {
        // console.log()
        this.btnflag = false;
        console.log('return');
        return;
      }
      const input = new FormData();

      if (this.f.customerType.value === 'INDIVIDUAL') {
        if (this.currOrgId === 792) {
          console.log(this.f.firstName.value.trim()+''+this.f.lastName)
          this.formData = {
            kycByChassis: true,
            vehicleMake: this.f.vehicleMake.value.trim(),
            vehicleModel : this.f.vehicleModel.value.trim(),
            chasisNumber : this.f.vehicleName.value.trim(),
            // vinNumber : this.f.vinNumber.value.trim(),
            engineNumber : this.f.engineNumber.value.trim(),
            registerationNumber : this.f.registerationNumber.value.trim(),
            deviceSerialNumber : "FORTUNER",
            deviceModelNumber : "A883i",
            customerType : this.f.customerType.value.trim(),
            customerName : this.f.firstName.value.trim()+' '+this.f.lastName.value.trim(),
            customerMobileNumber : this.f.mobileNo.value.trim(),
            customerEmail : this.f.email.value.trim(),
            customerAddress : this.f.address.value.trim(),
            documentType: this.f.documentType.value.trim(),
            documentNumber: this.f.documentNumber.value.trim(),
            ais140Complaint: this.f.ais140.value
            // isSoldOffLine: true
          };
          input.set('documentFile', this.individualFile);
          if(this.individualBackFile){
            input.set('documentBackFile', this.individualBackFile);
          }
        } else {
          this.formData = {
            vehicleMake: this.f.vehicleMake.value.trim(),
            vehicleModel : this.f.vehicleModel.value.trim(),
            chasisNumber : this.f.vehicleName.value.trim(),
            // vinNumber : this.f.vinNumber.value.trim(),
            engineNumber : this.f.engineNumber.value.trim(),
            registerationNumber : this.f.registerationNumber.value.trim(),
            deviceSerialNumber : this.f.deviceSerialNumber.value,
            deviceModelNumber : this.f.deviceModelNumber.value.trim(),
            customerType : this.f.customerType.value.trim(),
            customerName : this.f.firstName.value.trim()+''+this.f.lastName.value.trim(),
            customerMobileNumber : this.f.mobileNo.value.trim(),
            customerEmail : this.f.email.value.trim(),
            customerAddress : this.f.address.value.trim(),
            documentType: this.f.documentType.value.trim(),
            documentNumber: this.f.documentNumber.value.trim(),
            // isSoldOffLine: true

          };
          input.set('documentFile', this.individualFile);
          if(this.individualBackFile){
            input.set('documentBackFile', this.individualBackFile);
          }
        }

      } else if (this.f.customerType.value === 'CORPORATE') {
        if (this.currOrgId === 792) {
          this.formData = {
            kycByChassis: true,
            vehicleMake: this.f.vehicleMake.value.trim(),
            vehicleModel : this.f.vehicleModel.value.trim(),
            chasisNumber : this.f.vehicleName.value.trim(),
            // vinNumber : this.f.vinNumber.value.trim(),
            engineNumber : this.f.engineNumber.value.trim(),
            registerationNumber : this.f.registerationNumber.value.trim(),
            deviceSerialNumber : "FORTUNER",
            deviceModelNumber : "A883i",
            customerType : this.f.customerType.value.trim(),
            customerName : this.f.firstName.value.trim()+''+this.f.lastName.value.trim(),
            customerMobileNumber : this.f.mobileNo.value.trim(),
            customerEmail : this.f.email.value.trim(),
            customerAddress : this.f.address.value.trim(),
            documentType: this.f.documentType.value.trim(),
            documentNumber: this.f.documentPanNumber.value.trim(),
            gstNumber: this.f.gstNumber.value.trim(),
            authorisedPersonDocumentType: this.f.documentType.value.trim(),
            authorisedPersonDocumentNumber: this.f.documentPanNumber.value.trim(),
            ais140Complaint: this.f.ais140.value
          };
          input.set('documentFile', this.panFile);
          input.set('authorizedPersonDocumentFile', this.individualFile);
          if(this.individualBackFile){
            input.set('documentBackFile', this.individualBackFile);
          }
        } else {
          this.formData = {
            vehicleMake: this.f.vehicleMake.value.trim(),
            vehicleModel : this.f.vehicleModel.value.trim(),
            chasisNumber : this.f.vehicleName.value.trim(),
            // vinNumber : this.f.vinNumber.value.trim(),
            engineNumber : this.f.engineNumber.value.trim(),
            registerationNumber : this.f.registerationNumber.value.trim(),
            deviceSerialNumber : this.f.deviceSerialNumber.value,
            deviceModelNumber : this.f.deviceModelNumber.value.trim(),
            customerType : this.f.customerType.value.trim(),
            customerName : this.f.firstName.value.trim()+''+this.f.lastName.value.trim(),
            customerMobileNumber : this.f.customerMobileNumber.value.trim(),
            customerEmail : this.f.email.value.trim(),
            customerAddress : this.f.address.value.trim(),
            documentType: this.f.documentType.value.trim(),
            documentNumber: this.f.documentPanNumber.value.trim(),
            gstNumber: this.f.gstNumber.value.trim(),
            authorisedPersonDocumentType: this.f.documentType.value.trim(),
            authorisedPersonDocumentNumber: this.f.documentPanNumber.value.trim(),
            // isSoldOffLine: true

          };
          input.set('documentFile', this.panFile);
          input.set('authorizedPersonDocumentFile', this.individualFile);
          if(this.individualBackFile){
            input.set('documentBackFile', this.individualBackFile);
          }
        }
      }

    input.append('kyc', JSON.stringify(this.formData));
    // console.log(this.formData)

      this.authentication.createKycData(input)
        .pipe(first())
        .subscribe(
          res => {

            if (res.status === 200 || res.status === 204) {
              this.resLoader = false;
              this.createUser();
              this.btnflag = false;

            }

          },
          error => {
            this.resLoader =  false;
            this.errorMsg = true;
            this.firstApi == false;
            this.statusMsg = error.error.description ? error.error.description : error.error.message;
            this.kycErrorModal();
            this.btnflag = false;
          }
        );


  }

    onCustomerChange() {
      console.log('on change cust type');
      this.submitOptional = false;
      this.showIndividualTab = false;
      this.showCorporateTab = false;
      this.showIndividualTab = this.f.customerType.value === 'INDIVIDUAL';
      this.showCorporateTab = this.f.customerType.value === 'CORPORATE';
      this.f.documentType.setValue(this.showCorporateTab  ? 'PAN_CARD' : 'ADHAAR');

      // this.registerKycForm.controls['documentType'].clearValidators();
      // this.registerKycForm.controls['documentNumber'].clearValidators();
      // this.registerKycForm.controls['individualFile'].clearValidators();

      if (this.showIndividualTab) {
        this.registerKycForm.controls['documentType'].setValidators([Validators.required]);
        this.registerKycForm.controls['documentNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['individualFile'].setValidators([Validators.required]);
        this.registerKycForm.updateValueAndValidity();

      } else if (this.showCorporateTab) {
        this.registerKycForm.controls['documentPanNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['panFile'].setValidators([Validators.required]);
        this.registerKycForm.controls['gstNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedPersonDocumentType'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedPersonDocumentNumber'].setValidators([Validators.required]);
        this.registerKycForm.controls['authorisedFile'].setValidators([Validators.required]);
        this.registerKycForm.updateValueAndValidity();
      }
    }
    fileUpload(event) {
      this.individualFile = event.target.files[0];
      console.log(this.individualFile);
      this.fileStatus = event.target.files[0].name;
      console.log(this.individualFile.type);
      if (this.individualFile.type === 'application/pdf') {
        this.fileErrorMsg = false;
      } else {
        this.fileErrorMsg = true;
      }
    }
    fileUpload2(event) {
      this.individualBackFile = event.target.files[0];
      console.log(this.individualBackFile);
      this.fileStatus2 = event.target.files[0].name;
      console.log(this.individualBackFile.type);
      if (this.individualBackFile.type === 'application/pdf') {
        this.fileErrorMsg2 = false;
      } else {
        this.fileErrorMsg2 = true;
      }
    }
    panFileUpload(event) {
      this.panFile = event.target.files[0];
      this.panFileStatus = this.panFile.name;
      if (this.panFile.type === 'application/pdf') {
        this.filePanMsg = false;
      } else {
        this.filePanMsg = true;
      }
    }
    authorisedFileUpload(event) {
      this.authorisedFile = event.target.files[0];
      this.authorisedFileStatus = this.authorisedFile.name;
      if (this.authorisedFile.type === 'application/pdf') {
        this.fileAuthoriseMsg = false;
      } else {
        this.fileAuthoriseMsg = true;
      }
    }

}
