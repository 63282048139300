import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, filter, map, switchMap } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { UsersService, CustomValidatorService, ResourceAccessService, ModalService} from '../../_services';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit {
  // on init
  states: string[] = [
    'Andaman & Nicobar',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli',
    'Daman & Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu & Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ];
  selState: string;
  currOrgName: string;
  currOrgId: any;
  orgList: any = [];
  isShipBillAddSame = true;
  cartProductList: any[];

  cartItemPriceParam: any = [];
  cartItemPriceDetail: any;
  dealerDetail: any[];
  totalAmt: any;
  // Product cart form data
  // productCart : FormGroup;
  // productCartParam:{}
  // Add user form data
  addUsers: FormGroup;
  addUsersParam: {};
  cartItemData: any = [];
  // common
  submitted = false;
  resLoader = false;
  statusMsg: string;
  successMsg: boolean;
  errorMsg: boolean;

  paymentDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private authentication: UsersService,
    private resourceAccessService: ResourceAccessService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.orgList = this.resourceAccessService.orgList;
    this.currOrgId = this.resourceAccessService.currOrgId;
    this.currOrgName = this.resourceAccessService.currOrgName;
    this.selState = '';
    this.paymentDate =  new Date();
   }

  ngOnInit() {
    console.log(this.orgList);
    if (this.currOrgId) {
      this.fnCartProductList();
      this.fnDealerDetail();
      this.addUsers = this.formBuilder.group(
        {
          utrNo: ['', [Validators.required] ],
          utrNoConfirm: ['', Validators.required],
          amtPaid: ['', [Validators.required] ],
          paymentDate: ['', [Validators.required] ],
          billerName: ['', [Validators.required] ],
          billerEmail: [this.orgList.email, [Validators.required] ],
          billerPhone: [this.orgList.phoneNumber, [Validators.required] ],
          billerAdd1: ['', [Validators.required] ],
          billerAdd2: [''],
          billerCity: ['', [Validators.required] ],
          billerState: ['', [Validators.required] ],
          billerPin: ['', [Validators.required] ],
          billerGstn: [''],
          shipAsBill: [true],
          shipName: ['', [Validators.required] ],
          shipEmail: ['', [Validators.required] ],
          shipPhone: ['', [Validators.required] ],
          shipAdd1: ['', [Validators.required] ],
          shipAdd2: [''],
          shipCity: ['', [Validators.required] ],
          shipState: ['', [Validators.required] ],
          shipPin: ['', [Validators.required] ]
        },
        {
          validator: [
            CustomValidatorService.MatchPassword,
            // CustomValidatorService.validateNewPass
          ]
        }
      );
    }
  }
  // Getters : convenience getter for easy access to form fields
  get f() {
    return this.addUsers.controls;
  }

  // get pc() {
  //   return this.productCart.controls;
  // }

  private fnCartProductList() {
    this.authentication.getCartProductList(this.currOrgId)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.length > 0) {
            this.cartProductList = data;

            this.cartProductList.forEach((item, index) => {
              this.cartItemPriceParam.push({
                'productId' : item.id,
                'qty' :	item.minQty
              });
            });
            this.fnPriceList(this.cartItemPriceParam);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  private fnPriceList(param: any[]) {
    this.authentication.getCartPriceList(param, this.currOrgId)
    .pipe(first())
    .subscribe(
      data => {
        this.totalAmt = data.total;
        this.cartItemPriceDetail = data.itemPrices;
        this.cartItemData = param;
        console.log(this.cartItemPriceDetail);
      },
      error => {
        console.log(error);
      }
    );
  }

  SearchData(event: number, productId: number) {
    console.log(event);
    console.log(productId);
    this.cartItemPriceParam.forEach((item: any, index: any) => {
      console.log(this.cartItemPriceParam[index]['productId'] + '==' + productId);
      if (this.cartItemPriceParam[index]['productId'] === productId) {
        this.cartItemPriceParam[index]['qty'] = Number(event);
      }
    });
    console.log(this.cartItemPriceParam);
    this.fnPriceList(this.cartItemPriceParam);
  }


  private fnDealerDetail() {
    this.authentication.getDealerDetail(this.currOrgId)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.length > 0) {
            this.dealerDetail = data[0];
            this.fnSetDealerData(this.dealerDetail);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  private fnSetDealerData(data: any) {
    this.addUsers.setValue({
      utrNo: '',
      utrNoConfirm: '',
      amtPaid: '',
      paymentDate: '',
      billerName: data['helpName'],
      billerEmail: data['helpEmail'],
      billerGstn: data['gstn'],
      billerPhone: data['contactNumber'],
      billerAdd1: data['addressLine1'],
      billerAdd2: data['addressLine2'],
      billerCity: data['city'],
      billerState: data['state'],
      billerPin: data['pincode'],
      shipAsBill: true,
      shipName: data['helpName'],
      shipEmail: data['helpEmail'],
      shipPhone: data['contactNumber'],
      shipAdd1: data['addressLine1'],
      shipAdd2: data['addressLine2'],
      shipCity: data['city'],
      shipState: data['state'],
      shipPin: data['pincode']
    });
  }

  onToggleShipBillAdd(event: any) {
    if (event.checked) {
      console.log('disable shipping addresssssss');
      // this.fnSetDealerData(this.dealerDetail);
      this.addUsers.patchValue({
        'shipName': this.f.billerName.value.trim(),
        'shipEmail': this.f.billerEmail.value.trim(),
        'shipPhone': this.f.billerPhone.value.trim(),
        'shipAdd1': this.f.billerAdd1.value.trim(),
        'shipAdd2': this.f.billerAdd2.value.trim(),
        'shipCity': this.f.billerCity.value.trim(),
        'shipState': this.f.billerState.value.trim(),
        'shipPin': this.f.billerPin.value
      });
      this.isShipBillAddSame = event.checked;
    } else {
      // var updateDealerDetail:{};
      // updateDealerDetail = {
      //   utrNo: this.t.stationName.value.trim(),
      //   utrNoConfirm: this.t.stationName.value.trim(),
      //   amtPaid: this.t.stationName.value.trim(),
      //   paymentDate: this.t.stationName.value.trim(),
      //   billerName: this.t.stationName.value.trim(),
      //   billerEmail: this.t.stationName.value.trim(),
      //   billerPhone: this.t.stationName.value.trim(),
      //   billerAdd1: this.t.stationName.value.trim(),
      //   billerAdd2: this.t.stationName.value.trim(),
      //   billerCity: this.t.stationName.value.trim(),
      //   billerState: this.t.stationName.value.trim(),
      //   billerPin: this.t.stationName.value.trim(),
      //   shipAsBill: true,
      //   shipName: this.t.stationName.value.trim(),
      //   shipEmail: this.t.stationName.value.trim(),
      //   shipPhone: this.t.stationName.value.trim(),
      //   shipAdd1: this.t.stationName.value.trim(),
      //   shipAdd2: this.t.stationName.value.trim(),
      //   shipCity: this.t.stationName.value.trim(),
      //   shipState: this.t.stationName.value.trim(),
      //   shipPin: this.t.stationName.value.trim()
      // }
      console.log('enable shipping addresssssss');
      this.isShipBillAddSame = event.checked;
    }
  }

  onSubmitProductCart() {
    this.submitted = true;
    this.resLoader = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;
    // stop here if form is invalid
    if (this.addUsers.invalid) {
      this.resLoader = false;
      return;
    }
  }

  onSubmit(userType: string) {
    console.log('adddd data');
    this.submitted = true;
    this.resLoader = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;
    console.log(this.addUsers);
    // stop here if form is invalid
    if (this.addUsers.invalid) {
      this.resLoader = false;
      return;
    }

    var updateDealerDetail: object;
    updateDealerDetail = {
      'items': this.cartItemData,
      'amountPaid': this.f.amtPaid.value,
      'utr': this.f.utrNo.value,
      'dateOfPayment': this.f.paymentDate.value * 1,
      'buyerId': this.dealerDetail['id'],
      'orgId': this.currOrgId,
      'buyerName': this.f.billerName.value.trim(),
      'email': this.f.billerEmail.value.trim(),
      'gstn' : this.f.billerGstn.value.trim(),
      'phoneNumber': this.f.billerPhone.value,
      'addressLine1': this.f.billerAdd1.value.trim(),
      'addressLine2': this.f.billerAdd2.value.trim(),
      'city': this.f.billerCity.value.trim(),
      'state': this.f.billerState.value,
      'pincode': this.f.billerPin.value,
      'shippingAddressLine1': this.f.shipAdd1.value.trim(),
      'shippingAddressLine2': this.f.shipAdd2.value.trim(),
      'shippingCity': this.f.shipCity.value.trim(),
      'shippingState': this.f.shipState.value.trim(),
      'shippingPincode': this.f.shipPin.value
    };

    console.log(updateDealerDetail);

    this.authentication.addUsers(updateDealerDetail)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.resLoader = false;
          this.statusMsg = 'Order placed successfully';
          this.successMsg = true;
          this.errorMsg = false;
          setTimeout(() => {
            // reset local storage
            this.statusMsg = '';
            this.router.navigate(['/history']);
          }, 2000);
        },
        error => {
          console.log(error);
          this.resLoader = false;
          this.errorMsg = true;
          this.successMsg = false;
          if (error) {
            this.statusMsg = error.error.description ? error.error.description : error.error.message;
            this.UserErrorModal();
          } else {
            this.statusMsg = 'something went wrong, please try again.'
            this.UserErrorModal();

          }
        }
      );
  }

  closeModal(id: string) {
    // this.modalService.close('addUserMsgPopup');
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  UserErrorModal() {
    this.modalService.open('UserErrorModal');

  }

  closeUserErrorModal() {
    this.modalService.close('UserErrorModal');

  }

}
