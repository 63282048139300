import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor( 
    private authentication : AuthService,
    private router : Router
  ){ }

  canActivate(): boolean {
    if(!this.authentication.isUserlogIn){
      // not logged in so redirect to login page with the return url
      //this.rounter.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  // canActivate(
  //     next: ActivatedRouteSnapshot,
  //     state: RouterStateSnapshot
  //   ): Observable<boolean> | Promise<boolean> | boolean {
  //       if (!authentication.isUserlogIn) {
  //         localStorage.setItem('authRedirect', state.url);
  //       }
  //       if (!this.auth.tokenValid && !authentication.isUserlogIn) {
  //         this.auth.login();
  //         return false;
  //       }
  //       if (this.auth.tokenValid && authentication.isUserlogIn) {
  //         return true;
  //       }
  //     }
}
