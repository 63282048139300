import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService, ResourceAccessService, ModalService } from './../../_services';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {WindowRef} from './../../_helpers/window-ref';


@Component({
  selector: 'app-view-history',
  templateUrl: './view-history.component.html',
  styleUrls: ['./view-history.component.css']
})
export class ViewHistoryComponent implements OnInit {

  rightVwHeight: number;
  userTblHeight: number;
  userRoleList: any = [];
  orderStatusList: any = [];
  userRoleFilterForm: FormGroup;
  userLoginIdFilterForm: FormGroup;
  currUrl: string;
  selectedRole: string;
  currOrderStatus: string;
  usersDataCount: number;
  itemsPerPage: number;
  currentPage: number = 1;
  totalItems: number;
  offset: number = 0;
  paramData: any = {};
  orderData: any = [];
  resLoader: boolean;
  currUserId: string;
  currUserType: string;
  disableBtn: boolean = false;
  disableActionBtn: boolean = false;
  statusMsg: string;
  successMsg: boolean = false;
  errorMsg: boolean = false;

  chargingStationList: string[];
  selectedStation: string;
  stationStatusMsg: string = '';
  stationStatusFlag: boolean = false;
  resLoaderPopup: boolean = false;

  // updateStatusForm: FormGroup;
  // updateStatusFrmParam:{};
  // showupdateStatusFrmFrmError:boolean;
  // reservationListData: any[];

  fromDate: any;
  toDate: any;
  minDate: any;
  maxDate: any;
  dateErrorFlag = false;
  maxDateLimit: any;
  nativeWindow: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private usersService: UsersService,
    private resourceAccessService: ResourceAccessService,
    private winRef: WindowRef
  ) {
    this.currUrl = this.router.url;
    this.minDate = new Date();
    this.minDate = this.minDate.setHours(0, 0, 0);
    this.fromDate =  new Date();
    this.maxDate = new Date ();
    this.maxDate = this.maxDate.setHours(23, 59, 59);
    this.toDate = new Date();
    this.nativeWindow = winRef.nativeWindow;
    this.maxDateLimit = new Date ( Date.now() );
    // console.log('Window object', winRef.nativeWindow);

  }

  ngOnInit() {
    this.userRoleList = this.resourceAccessService.orgList;
    this.orderStatusList = this.resourceAccessService.orderStatusList;
    this.selectedRole = this.resourceAccessService.currOrgId;
    this.userRoleFilterForm = this.formBuilder.group({
      userRole: this.selectedRole
    });
    this.userLoginIdFilterForm = this.formBuilder.group({
      userLoginId : ['']
    });

    this.paramData = this.createParamObj();
    if (this.paramData) {
      if (this.userRoleList[0]['memberType'] == 'MEMBER') {
        this.usersService.getDealerDetail(this.selectedRole)
        .pipe(first())
        .subscribe(
          data => {
            if (data.length > 0) {
              this.paramData.orgId = this.selectedRole;
              this.paramData.departmentId = data[0]['id'];
              this.loadUsers(this.paramData);
            } else {
              this.orderData = [];
            }
          },
          error => {
            console.log(error);
            this.orderData = [];
          }
        );
      } else {
        this.loadUsers(this.paramData);
      }
    }
  }
  // Getters : convenience getter for easy access to form fields
  get f2() { return this.userLoginIdFilterForm.controls; }
  // To set parameters for API calling
  private createParamObj() {
    this.currentPage = 1;
    this.offset = 0;
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page
    this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 100;
    // this.paramData.actionGroup = this.selecttoedRole;
    // this.resourceAccessService.selectedRole = this.selectedRole;
    this.paramData.offset = this.offset * this.itemsPerPage;
    this.paramData.limit = this.itemsPerPage;

    var formatFromDate: any, formatToDate: any;
    if(!this.fromDate || !this.toDate) {
      this.fromDate = new Date();
      this.fromDate = this.fromDate.setHours(0, 0, 0 );
      this.toDate = new Date();
      this.toDate = this.toDate.setHours(23, 59, 59);
    } else {
      formatFromDate = this.fromDate;
      formatToDate = this.toDate;
      formatFromDate =  formatFromDate.setHours(0, 0, 0);
      formatToDate = formatToDate.setHours(23, 59, 59);
    }
    if (formatFromDate > formatToDate) {
      this.dateErrorFlag = true;
      this.orderData = [];
      return;
    } else {
      this.dateErrorFlag = false;
    }
    this.paramData.startTime =  formatFromDate * 1;
    this.paramData.endTime =  formatToDate * 1;
    return this.paramData;

  }

  // to call user list API
  private loadUsers(param: object) {
    this.usersService.getOrderList(param)
      .pipe(first())
      .subscribe(
        data => {
          this.orderData = data.orders;
          this.totalItems = data.totalCount;
          this.resLoader = false;
        },
        error => {
          console.log(error);
        }
      );
  }

  // trigger when user set filter by USER Name/ID
  onUserLoginIdFilter() {
    this.resLoader = true;
    // reset paggination value
    this.currentPage = 1;
    this.offset = 0;
    this.orderData = [];
    if (this.f2.userLoginId.value != '' && this.f2.userLoginId.value != null) {
      this.paramData.departmentCode =  this.f2.userLoginId.value != '' ? this.f2.userLoginId.value.replace(/^\s+|\s+$/gm, '') : null;
    } else if (this.paramData.departmentCode) {
      delete this.paramData.departmentCode;
    }

    this.paramData = this.createParamObj(); // function calling to set API param
    this.loadUsers(this.paramData); // API call
  }

  onDateFilter() {
    this.paramData = this.createParamObj(); // function calling to set API param
    this.loadUsers(this.paramData); // API call
  }

  downloadExcel() {
    const data = {
      startTime : this.paramData.startTime,
      endTime : this.paramData.endTime
    };
    const access_token = localStorage.getItem('auth_info');
    const parsedObject = JSON.parse(access_token);
    // const token_object = parsedObject.toString().replace(/"/g, '');

    const  res = parsedObject.auth_key;
    let url;
    if (this.paramData.departmentId) {
      url = `${environment.apiV2Url}` + '/oms/order/download?' +
      // tslint:disable-next-line:max-line-length
      'startTime=' + data.startTime + '&endTime=' + data.endTime + '&orgId=' + this.paramData.orgId + '&departmentId=' + this.paramData.departmentId + '&access_token=' + res + '';
    } else {
      url = `${environment.apiV2Url}` + '/oms/order/download?' +
      // tslint:disable-next-line:max-line-length
      'startTime=' + data.startTime + '&endTime=' + data.endTime + '&access_token=' + res + '';
    }
    this.winRef.nativeWindow.open(url, '_blank');
  }
  // trigger when user change page
  onPageChange(page: number) {
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    console.log(this.rightVwHeight);
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.paramData.offset = this.offset * this.itemsPerPage;

    // this.paramData = this.createParamObj();
    this.orderData = [];

    this.loadUsers(this.paramData); // API call
  }
  // To close delete user popup
  closeModal(id: string, action: boolean) {
    this.disableActionBtn = false;
    this.disableBtn = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;
  }
  // To open delete user popup
  openModal(popupId: any) {
    this.disableBtn = false;
    this.statusMsg = '';
    this.modalService.open(popupId);
  }

  // compareObjects(o1: any, o2: any): boolean {
  //   return o1.name === o2.name && o1.id === o2.id;
  // }

}
