import { Component, OnInit, Input } from '@angular/core';
import {  ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { FotaService } from './../_services/fota.service';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.css']
})
export class FirmwareComponent implements OnInit {
  firmwareData = [];
  resLoader: boolean;
  firmwareForm: FormGroup;
  popupStatusMsg = '';
  submitted = false;
  statusMsg: any;
  version: any;
  type: any;
  fileStatus = 'No File Chosen';
  uploadedFile: any;
  firmwareTypes = ['APP', 'BOOT'];
  selectedFirmwareType = '';
  paramFirmwareData: any = {};

  currentPage = 1;
  offset = 0;
  totalItems = 100;
  rightVwHeight: number;
  userTblHeight: number;
  itemsPerPage: any;
  size: any;
  fileTypeValid = false;

  constructor(
    private modalService: ModalService,
    private fotaService: FotaService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.firmwareForm = this.formBuilder.group({

      version: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          // Validators.pattern(/^[a-zA-Z0-9\-_.\s]+$/i)
          // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/)
          Validators.pattern(/^\b([a-zA-Z0-9.,@#$%&*\-_\/\s])+\b$/i)
        ]
      ],
      selectedFirmwareType: ['', Validators.required],
      firmwareFile: ['', Validators.required]
    });
    this.getAllFirmwares(null);
  }
  get f() { return this.firmwareForm.controls; }

  closeModal(id: string) {
    this.firmwareForm.reset();
    this.uploadedFile = null;
    this.selectedFirmwareType = '';
    this.submitted = false;
    this.fileStatus = 'No File Chosen';
    this.firmwareForm.markAsPristine();
    this.modalService.close(id);
    this.fileTypeValid = false;
  }

  onSelectType(data) {
    this.selectedFirmwareType = data.value;
    console.log(this.selectedFirmwareType);
  }

  fileUpload(event) {
    this.uploadedFile = event.target.files[0];
    this.fileStatus = this.uploadedFile.name;
    if (this.uploadedFile.name.indexOf('.hex') > 0) {
      console.log('valid File Name');
      this.fileTypeValid = false;
    } else {
      this.fileTypeValid = true;
      return;
    }

    const index = this.uploadedFile.name.indexOf('.');
    this.firmwareForm.controls['version'].setValue(this.uploadedFile.name.substr(0, index));
  }

  onChangeAction(data) {
    console.log(data);
    if (data.value === 'firmwarePopup') {
      // this.addBatterySetForm.reset();
      this.modalService.open('firmwarePopup');
    }
  }

  createFirmware() {
    this.submitted = true;
    this.popupStatusMsg = '';

    if (this.firmwareForm.invalid || this.fileTypeValid) {
      console.log('return');
      return;
    }

    this.version = this.f.version.value.trim();
    this.type = this.selectedFirmwareType;
    console.log(this.type);
    const input = new FormData();
    input.set('file', this.uploadedFile);


    this.fotaService.createFirmware(this.version, this.type, input)
      .pipe(first())
      .subscribe(
        data => {
          this.resLoader = false;
          this.closeModal('firmwarePopup');
          this.getAllFirmwares(null);
          // this.router.reload();
        },
        error => {
          this.resLoader =  false;
          // this.errorMsg = true;
          this.popupStatusMsg = error.error.description ? error.error.description : error.error.message;
          console.log(this.popupStatusMsg);
        }
      );
    }

    private getAllFirmwares(params: any) {
      this.resLoader = true;
      // const formData = {
      //   offset: this.offset,
      //   size: this.itemsPerPage
      // };
      // console.log(formData);
      this.fotaService.getFirmwaresFilter(params)
        .pipe(first())
        .subscribe(
          data => {
            this.firmwareData = data;
            this.resLoader = false;
          },
          error => {
            console.log(error);
            this.resLoader = false;
          }
        );
    }

    toApplyFirmwareFilters() {
      this.firmwareData = [];
      // console.log('toApplyColFilter');
      this.getAllFirmwares(this.paramFirmwareData);
    }
    toSetFirmwareFilters(evt: any, searchType: any) {
      // console.log('toSetColFilters');
      const filterVal = evt.target.value.trim();
      console.log(filterVal, searchType);
      if (searchType === 'firmwareFilters') {
        if (filterVal !== '') {
          this.paramFirmwareData.firmwareVersion = filterVal;
        } else {
          delete this.paramFirmwareData.firmwareVersion;
        }
      }
      // this.getAllFirmwares(this.paramFirmwareData);
    }

    // trigger when user change page

    onPageChange(page: number) {
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

      this.resLoader = true;
      this.currentPage = page;
      this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
      this.offset = this.offset * this.itemsPerPage;
      this.firmwareData = [];

      this.getAllFirmwares(null); // API call
    }
  }


