import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceAccessService } from '../_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    private router : Router,
    private resourceaccess : ResourceAccessService
  ) { }

  ngOnInit() {
    // console.log(this.resourceaccess.homePath);
    this.router.navigate([this.resourceaccess.homePath]);
  }
}
