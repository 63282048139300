import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { Md5 } from 'md5-typescript';
import { ModalService } from '../../_services/modal.service';





import { AuthService, CustomValidatorService } from '../../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {

  online$: Observable<boolean>;
  name: string;
  email;

  chnagePassForm: FormGroup;
  resLoader = false;
  submitted = false;
  statusMsg:string;
  successMsg: boolean;
  errorMsg:boolean;
  submitBtnFlag: boolean;
  md5Pass: String;

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthService,
    private router:Router,
    private modalService: ModalService
  ) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus();
  }

  public networkStatus() {
    this.online$.subscribe(value => {
      this.name = `${value}`;
    })
  }

  ngOnInit() {
    // this.ResendOtp();
    this.chnagePassForm = this.formBuilder.group(
      {
        otp: ['', [Validators.required, Validators.minLength(6)]],
        newPass: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]],
        confirmPass: ['', Validators.required]
      },
      {
        validator: [
          CustomValidatorService.MatchPass
        ]
      }
    );
    // reset login status
    // this.authentication.logout();
    this.email = this.authentication.forgotEmail;
  }

  ResendOtp() {
    this.modalService.open('ResendOtp');
    // this.successMsg = true;
    // this.errorMsg = false;
    // this.statusMsg = 'Otp Resend Successfully'

    var data: object;
    data = {
      'email': this.email,
      'otpType': 'FORGOT_PASSWORD',
    };

    this.authentication.forgetpass(data)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data.data.success);
          this.resLoader = false;
          // this.statusMsg = 'Link to change password has been sent to registered email address';
          this.successMsg = true;
          this.submitBtnFlag = true;
          this.successModal1();

        },
        error => {
    // this.router.navigate(['/changePass']);

          // console.log(error.data);
          this.statusMsg = error.error.error_description;;
          this.errorMsg = true;
          this.resLoader = false;
          this.submitBtnFlag = false;
        }
      );
  }

  closeResendOtp() {
    this.modalService.close('ResendOtp');

  }

  closesuccessModal() {
    this.modalService.close('successModal')
  }

  successModal() {
  this.modalService.open('successModal')
  }

  closeerrorModal() {
    this.modalService.close('errorModal')
  }
  successModal1() {
    this.modalService.open('successModal1')
    }
  
    closeerrorModal1() {
      this.modalService.close('successModal1')
      this.router.navigateByUrl("/login")
    }

  errorModal() {
  this.modalService.open('errorModal')
  }

  // convenience getter for easy access to form fields
  get f() { return this.chnagePassForm.controls; }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.chnagePassForm.invalid) {
      this.statusMsg = '';
      this.errorMsg = false;
      this.successMsg = false;
      return;
    }

    if(this.name != 'true'){
      this.statusMsg = 'There is no active internet connection. Please enable your internet';
      this.errorMsg = true;
      this.successMsg = false;
      return;
    }

    this.resLoader = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;
    this.md5Pass = Md5.init(this.f.newPass.value);


    var data: object;
    data = {
      'otp': this.f.otp.value,
      'password': this.md5Pass,
      'email': this.email,
    };
    
    this.authentication.changePass(data)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data.data.success);
          this.resLoader = false;
          this.statusMsg = 'Password changed successfully';
          this.successMsg = true;
          this.submitBtnFlag = true;
          this.successModal1();
        },
        error => {
          // console.log(error.error.data.responseDesc);
          this.statusMsg = error.error.data.responseDesc;
          this.successModal();
          this.errorMsg = true;
          this.resLoader = false;
          this.submitBtnFlag = false;
        }
      );
  }

}


