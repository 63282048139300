import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ResourceAccessService } from './resource-access.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TitleService {
  orgType;

  constructor(private resource:ResourceAccessService , private auth: AuthService ) { }

  changeTitle() {
    this.auth.getResourceAccess().pipe(first()).subscribe(
      data => {
        // console.log(data);
        this.resource.orgTitle = data[0]['memberType'];
        console.log(this.resource.orgTitle);
        if (data[0]['memberType'] === 'OWNER') {
          document.title = 'Dealer Portal';
        } else {

        }

      }
    )
    // console.log(this.resource.orgTitle);

  }
}
