import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent,HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';
// import { Observable } from 'rxjs/Rx';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  getUsersList(data:{}) {
    return this.http.get<any>(
      `${environment.apiV2Url}/dm`,
      {
        params: data
      });
  }

  getUserById(userName:string){
    return this.http.get<any>(`${environment.apiV2Url}/um?username=`+userName);
  }

  getCartProductList(dealerId:number){
    return this.http.get<any>(`${environment.apiV2Url}/oms/products/`+dealerId);
  }

  getDealerDetail(orgId:any){
    return this.http.get<any>(`${environment.apiV2Url}/dm?orgId=`+orgId);
  }

  getCartPriceList(orderDetail:any[], dealerId:number){
    return this.http.post<any>(
      `${environment.apiV2Url}/oms/pricing/`+dealerId,
      orderDetail
    )
  }

  getOrderList(data:{}){
    return this.http.get<any>(
      `${environment.apiV2Url}/oms/orders`,
      {
        params: data
      });
  }

  uploadDealers(formData:{}, orgId:any){
    return this.http.post<any>(
      `${environment.apiV2Url}/dm/upload/DEALER?orgId=`+orgId,
      formData,
      // {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'multipart/form-data',
      //   })
      // }
    )
  }
  // To add users
  addUsers(data:any){
    //console.log(email_id);
    return this.http.post<any>(`${environment.apiV2Url}/oms/place`,data);
  }

  // to link charging station users
  linkChargingStation(data:any){
    //console.log(email_id);
    return this.http.post<any>(`${environment.apiV2Url}/dm/CHARGING_STATION`,data);
  }

  grantAccess(data:any){
    return this.http.patch<any>(`${environment.apiV2Url}/ra/grantorrevoke`,data);
  }

  editUser(data:object){
    return this.http.put<any>(`${environment.apiV2Url}/um`,data);
  }

  deleteDealerApi(dealerId:string){
    return this.http.delete<any>(`${environment.apiV2Url}/dm/`+dealerId+'?category=DEALER');
  }
  // To create kyc
  createKycData(formData: {}){
    return this.http.post<any>(`${environment.apiV2Url}/kyc`, formData, { observe: 'response' })
  }

  getKycList(data: {}) {
    return this.http.get<any>(
      `${environment.apiV2Url}/kyc`,
      {
        params: data
      });
  }

  approveKyc(data: {}) {
    return this.http.post<any>(`${environment.apiV2Url}/kyc/update`, data).toPromise();
  }


}
