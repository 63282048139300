import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CotaService {

  constructor(private http: HttpClient) { }
  public commandList: any = [];

  getCommandsList(type) {
    return this.http.get<any>(`${environment.apiUrl}/fm/fota/device/commands?commandType=` + type);
  }

  addConfigDetail(data: any) {
    return this.http.put<any>(`${environment.apiUrl}/fm/fota/c2d`, data);
  }
  getCotaDetails(data: {}) {
    return this.http.get<any>(`${environment.apiUrl}/fm/fota/history?otaType=COTA`,
      {
        params: data
      });
  }

  getCotaDeviceDetails(id, data: {}) {
    return this.http.get<any>(`${environment.apiUrl}/fm/fota/history/device/` + id,
    {
      params: data
    });
  }
}
