
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, mapTo } from 'rxjs/operators';
import { Observable, fromEvent, merge, of } from 'rxjs';

import { AuthService, CustomValidatorService, ModalService } from '../../_services';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {

  online$: Observable<boolean>;
  name: string;

  forgotPassForm: FormGroup;
  resLoader = false;
  submitted = false;
  statusMsg:string;
  successMsg: boolean;
  errorMsg:boolean;
  submitBtnFlag:boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthService,
    private router: Router,
    private modalService: ModalService

  ) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus();
  }

  public networkStatus() {
    this.online$.subscribe(value => {
      this.name = `${value}`;
    })
  }

  ngOnInit() {
    this.forgotPassForm = this.formBuilder.group(
      {
      emailId: ['', [Validators.required, Validators.email]]
      },
      {
        validator: CustomValidatorService.validateEmailid // your validation method
      }
    );
    // reset login status
    this.authentication.logout();
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotPassForm.controls; }

  onSubmit() {
    // [routerLink] = "['../','forgotpass']"

    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPassForm.invalid) {
      this.statusMsg = '';
      this.errorMsg = false;
      this.successMsg = false;
      return;
    }

    if(this.name != 'true'){
      this.statusMsg = 'There is no active internet connection. Please enable your internet';
      this.errorMsg = true;
      this.successMsg = false;
      return;
    }

    this.resLoader = true;
    this.statusMsg = '';
    this.successMsg = false;
    this.errorMsg = false;


    var data: object;
    data = {
      'email': this.f.emailId.value,
      'otpType': 'FORGOT_PASSWORD',
    };

    this.authentication.forgetpass(data)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data.data.success);
    this.router.navigate(['/changePass']);

          this.resLoader = false;
          this.statusMsg = 'Link to change password has been sent to registered email address';
          this.successMsg = true;
          this.submitBtnFlag = true;

        },
        error => {
    // this.router.navigate(['/changePass']);

          //console.log(error.data);
          this.statusMsg = error.error.error_description;
          this.errorMsg = true;
          this.resLoader = false;
          this.submitBtnFlag = false;
          this.successModal();

        }
      );
  }


  successModal() {
    this.modalService.open('successModal');

  }

  closesuccessModal() {
    this.modalService.close('successModal');

  }


}

