import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Location} from "@angular/common";
import { first } from 'rxjs/operators';
import { AuthService, ResourceAccessService } from '../../_services';
@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WebLayoutComponent implements OnInit {

  homeUrl: string;
  currUserDetail: {};
  changePassForm: FormGroup;
  resLoader = false;
  submitted = false;
  statusMsg: string;
  successMsg: boolean;
  errorMsg: boolean;
  hashPass: string;
  hashOldPass: string;
  frmData: {};
  accessData: {};
  countReports: number;
  resourceAccessList: any = [];
  public href: string = "";
  tabData: any;
  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthService,
    private router: Router,
    private location: Location,
    private resourceaccess: ResourceAccessService
  ) {
    this.href = this.router.url;
    console.log(this.href);
  }

  ngOnInit() {
    this.loggedInUser();
  }



  private loggedInUser() {
    this.authentication.loggedInUser()
      .pipe(first())
        .subscribe(
          data => {
            this.currUserDetail = data.data.user;
            this.getPermissionsTabs();
            // this.getResourceAccess();
          },
          error => {
            console.log(error);
            localStorage.clear();
            this.router.navigate(['/login']);
          }
        );
  }

  private getPermissionsTabs() {
    this.authentication.getPermissionsTabs()
      .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data.length > 0) {
              this.tabData = data[0];
              console.log(this.tabData);
            } else {
              this.tabData = {'name': ''};
            }
            this.getResourceAccess();
          },
          error => {
            console.log(error);
          }
        );
  }


  private getResourceAccess() {
    this.authentication.getResourceAccess()
      .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data.length) {
              console.log(data[0]['memberType']);
              if (data[0]['memberType'] === 'OWNER') {
                this.countReports++;
                const itemObj = {
                  'name': 'Dealers',
                  'slug': 'dealers',
                  'seq': 1,
                  'sub1List': null
                };
                const itemObj1 = {
                  'name': 'KYC',
                  'slug': 'kyc',
                  'seq': 5,
                  'sub1List': null
                };
                const itemObj2 = {
                  'name': 'History',
                  'slug': 'history',
                  'seq': 4,
                  'sub1List': null
                };

                this.resourceAccessList.push(itemObj);
                this.resourceAccessList.push(itemObj1);
                this.resourceAccessList.push(itemObj2);

              }
              if (data[0]['memberType'] === 'MEMBER') {
                this.countReports++;
                const itemObj = {
                  'name': 'Place Order',
                  'slug': 'order',
                  'seq': 2,
                  'sub1List': null
                };
                const itemObj1 = {
                  'name': 'KYC',
                  'slug': 'kyc',
                  'seq': 6,
                  'sub1List': null
                };
                const itemObj6 = {
                  'name': 'History',
                  'slug': 'history',
                  'seq': 4,
                  'sub1List': null
                };
                const itemObj2 = {
                  'name': 'Device Groups',
                  'slug': 'viewDeviceGroups',
                  'seq': 7,
                  'sub1List': null
                };
                const itemObj3 = {
                  'name': 'Firmware',
                  'slug': 'Firmware',
                  'seq': 8,
                  'sub1List': null
                };
                const itemObj4 = {
                  'name': 'FOTA',
                  'slug': 'fota',
                  'seq': 9,
                  'sub1List': null
                };
                const itemObj5 = {
                  'name': 'Config Update',
                  'slug': 'cota',
                  'seq': 10,
                  'sub1List': null
                };
                // const itemObj7= {
                //   'name': 'Register Vehicle',
                //   'slug': 'Register Vehicle',
                //   'seq': 11,
                //   'sub1List': null
                // };
                if (this.tabData.name === 'FOTA') {
                  this.resourceAccessList.push(itemObj2);
                  this.resourceAccessList.push(itemObj3);
                  this.resourceAccessList.push(itemObj4);
                  this.resourceAccessList.push(itemObj5);
                  // this.resourceAccessList.push(itemObj7);

                } else {
                  this.resourceAccessList.push(itemObj);
                  this.resourceAccessList.push(itemObj1);
                  this.resourceAccessList.push(itemObj6);
                  // this.resourceAccessList.push(itemObj7);
                }
              }

              if (data[0]['id'] === 792) {
                const itemObj3 = {
                  'name': 'Register Vehicle',
                  'slug': 'registerVehicle',
                  'seq': 11,
                  'sub1List': null
                };
                this.resourceAccessList.push(itemObj3);
              }



              this.homeUrl = this.resourceAccessList[0]['slug'];
              this.resourceaccess.homePath = this.resourceAccessList[0]['slug'];
              this.resourceaccess.orgList = data;
              this.resourceaccess.currOrgId = data[0]['id'];
              this.resourceaccess.orgType = data[0]['memberType'];
              this.resourceaccess.currOrgName = data[0]['name'];
              if (this.href === '/' || this.href === '') {
                this.router.navigate([this.resourceaccess.homePath]);
              }
            } else {
              localStorage.clear();
              this.router.navigate(['/login']);
            }
            console.log(this.resourceAccessList);
            // localStorage.setItem("resorceData", JSON.stringify())
          },
          error => {
            console.log(error);
          }
        );
  };



  // private createMenuObj(userType:string, accessType:string) {
  //   if (accessType == 'USERS') {
  //     this.countReports++;
  //     let itemObj = {
  //       'key':accessType,
  //       'name':'Dealers',
  //       'slug':'dealer',
  //       'seq':1,
  //       'sub1List': null
  //     };
  //     this.resourceAccessList.push(itemObj);
  //   }
  //   if (accessType == 'USERS') {
  //     this.countReports++;
  //     let itemObj = {
  //       'key':accessType,
  //       'name':'Place Order',
  //       'slug':'order',
  //       'seq':2,
  //       'sub1List': null
  //     };
  //     this.resourceAccessList.push(itemObj);
  //   }
  // }
}
