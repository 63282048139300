import { Component, OnInit, Input } from '@angular/core';
import {  ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { FotaService } from './../_services/fota.service';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fota-status',
  templateUrl: './fota-status.component.html',
  styleUrls: ['./fota-status.component.css']
})
export class FotaStatusComponent implements OnInit {

  fotaList = [];
  resLoader: boolean;
  fotaForm: FormGroup;
  popupStatusMsg = '';
  submitted = false;
  statusMsg: any;
  dealerGroups = [];
  firmwareData = [];
  selectedGroupId: any;
  selectedFirmware: any;
  force = true;

  currentPage = 1;
  offset = 0;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;
  itemsPerPage: any;
  size: any;

  constructor(
    private modalService: ModalService,
    private fotaService: FotaService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.fotaForm = this.formBuilder.group({
      selectedFirmware: ['', Validators.required],
      selectedGroupId: ['', Validators.required],
      force: [true, Validators.required]
    });

    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page


    this.getFotaHistory();
    this.getDealerGrous();
    this.getFirmwares();
  }
  get f() { return this.fotaForm.controls; }

  closeModal(id: string) {
    this.fotaForm.reset();
    this.selectedGroupId = null;
    this.selectedFirmware = null;
    this.submitted = false;
    this.force = true;
    this.modalService.close(id);
  }

  onChangeAction(data) {
    if (data.value === 'fotaPopup') {
      this.modalService.open('fotaPopup');
    }
  }

    private getDealerGrous() {
      this.resLoader = true;
      this.fotaService.getDealerGroupsList()
        .pipe(first())
        .subscribe(
          data => {
            this.dealerGroups = data.deviceGroups;
            this.resLoader = false;
          },
          error => {
            console.log(error);
          }
        );
    }

    private getFirmwares() {
      this.resLoader = true;
      this.fotaService.getFirmwares()
        .pipe(first())
        .subscribe(
          data => {
            this.firmwareData = data;
            this.resLoader = false;
          },
          error => {
            console.log(error);
          }
        );
    }


      onSelectDeviceGroup(data) {
        this.selectedGroupId = data.value;
      }

      onSelectFirmware(data) {
        this.selectedFirmware = data.value;
      }

      createFota() {
        this.submitted = true;
        this.popupStatusMsg = '';
        if (this.fotaForm.invalid) {
          return;
        }

        const formData = {
          'groupCode' : this.selectedGroupId,
          'firmwareCode' : this.selectedFirmware,
          'force': this.f.force.value
        };
        this.fotaService.createFota(formData)
          .pipe(first())
          .subscribe(
            data => {
              this.resLoader = false;
              this.closeModal('fotaPopup');
              this.getFotaHistory();
            },
            error => {
              this.resLoader =  false;
              // this.errorMsg = true;
              this.popupStatusMsg = error.error.description ? error.error.description : error.error.message;
            }
          );
      }

    private getFotaHistory() {
      this.resLoader = true;
      const formData = {
        offset: this.offset,
        size: this.itemsPerPage
      };
      this.fotaService.getFotaHistory(formData)
        .pipe(first())
        .subscribe(
          data => {
            this.totalItems = data.count;
            this.fotaList = data.otaInvocationModels;
            this.resLoader = false;
          },
          error => {
            console.log(error);
          }
        );
    }

    // trigger when user change page

    onPageChange(page: number) {
      // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;
        this.resLoader = true;
        this.currentPage = page;
        this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
        this.offset = this.offset * this.itemsPerPage;
        this.fotaList = [];
        this.getFotaHistory(); // API call
      }
  }
