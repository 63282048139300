import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { LoginComponent } from './auth/login/login.component';
import { WebLayoutComponent } from './_layout/web-layout/web-layout.component';
import { HomeComponent } from './home/home.component';
import { RequestNotFoundComponent } from './request-not-found/request-not-found.component';
import { DealerComponent, ViewDealerComponent } from './dealer';
import { OrderComponent, PlaceOrderComponent} from './order';
import { HistoryComponent, ViewHistoryComponent} from './history';
import { KycDetailsComponent } from './kyc-details/kyc-details.component';
import { KycAddComponent } from './kyc-add/kyc-add.component';
import { DealerGroupsComponent } from './dealer-groups/dealer-groups.component';
import { DeviceGroupComponent } from './device-group/device-group.component';
import { FirmwareComponent } from './firmware/firmware.component';
import { FotaStatusComponent } from './fota-status/fota-status.component';
import { FotaDetailsComponent } from './fota-details/fota-details.component';
import { CotaDetailsComponent } from './cota-details/cota-details.component';
import { ChooseDevicesComponent } from './choose-devices/choose-devices.component';
import { from } from 'rxjs';
import { CotaCommandsComponent } from './cota-commands/cota-commands.component';
import { RegisterVehicleComponent } from './register-vehicle/register-vehicle.component';
import { ForgotPassComponent } from './auth/forgot-pass/forgot-pass.component';
import { ChangePassComponent } from './auth/change-pass/change-pass.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title : 'Login Page' }
  },
  {
    path: 'forgotpass',
    component:  ForgotPassComponent,
    data: { title: 'Forgot Password' }
  },
  {
    path: 'changePass',
    component:  ChangePassComponent,
    data: { title: 'Change Password' }
  },
  {
    path: '',
    component: WebLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'dealers',
        component: DealerComponent,
        children: [
          {
            path: '',
            component: ViewDealerComponent,
            data: { title: 'View Dealer' }
          }
        ]
      },
      {
        path: 'order',
        component: OrderComponent,
        children: [
          {
            path: '',
            component: PlaceOrderComponent,
            data: { title: 'Place Order' }
          }
        ]
      },
      {
        path: 'history',
        component: HistoryComponent,
        children: [
          {
            path: '',
            component: ViewHistoryComponent,
            data: { title: 'View Order' }
          }
        ]
      },
      {
        path: 'createKyc',
        component: KycAddComponent,
        children: [
          {
            path: '',
            component: KycAddComponent,
            data: { title: 'Create Kyc' }
          }
        ]
      },
      {
        path: 'kyc',
        component: KycDetailsComponent,
        children: [
          {
            path: '',
            component: KycDetailsComponent,
            data: { title: 'kyc' }
          },
        ]
      },
      {
        path: 'viewDeviceGroups',
        component: DealerGroupsComponent,
        children: [
          {
            path: '',
            component: DealerGroupsComponent,
            data: { title: 'Device Groups' }
          },
        ]
      },
      {
        path: 'viewDevicesDetail',
        component: DeviceGroupComponent,
        children: [
          {
            path: '',
            component: DeviceGroupComponent,
            data: { title: 'Device Details' }
          },
        ]
      },
      {
        path: 'chooseDevices',
        component: ChooseDevicesComponent,
        children: [
          {
            path: '',
            component: ChooseDevicesComponent,
            data: { title: 'Choose Devices' }
          },
        ]
      },
      {
        path: 'Firmware',
        component: FirmwareComponent,
        children: [
          {
            path: '',
            component: FirmwareComponent,
            data: { title: 'Device Details' }
          },
        ]
      },
      {
        path: 'fota',
        component: FotaStatusComponent,
        children: [
          {
            path: '',
            component: FotaStatusComponent,
            data: { title: 'Device Details' }
          },
        ]
      },
      {
        path: 'fotaDetails',
        component: FotaDetailsComponent,
        children: [
          {
            path: '',
            component: FotaDetailsComponent,
            data: { title: 'Device Details' }
          },
        ]
      },
      {
        path: 'cota',
        component: CotaDetailsComponent,
        children: [
          {
            path: '',
            component: CotaDetailsComponent,
            data: { title: 'Config Update' }
          },
        ]
      },
      {
        path: 'cotaDetails',
        component: CotaCommandsComponent,
        children: [
          {
            path: '',
            component: CotaCommandsComponent,
            data: { title: 'Config Details' }
          },
        ]
      },
      {
        path: 'registerVehicle',
        component: RegisterVehicleComponent,
        data: { title: 'Registration and KYC for Fortuner' }

      },
      { path: '**',
        component: RequestNotFoundComponent,
        data: { title: 'Page not found - 4O4' }
      },

    ]
  },
  { path: '**',
    component: LoginComponent,
    data: { title: 'Page not found - 4O4' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        useHash: true,
       // enableTracing: true
      }
    )
  ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
