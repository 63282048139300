import { Component, OnInit } from '@angular/core';
import { FotaService } from './../_services/fota.service';
import { ActivatedRoute , Router} from '@angular/router';
import { first, switchMap, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import {  ModalService } from '../_services';

@Component({
  selector: 'app-choose-devices',
  templateUrl: './choose-devices.component.html',
  styleUrls: ['./choose-devices.component.css']
})
export class ChooseDevicesComponent implements OnInit {

  deviceList: any = [];
  resLoader: boolean;
  paramData: any;
  queryParam: {[k: string]: any} = {};
  selectedParam: {[i: string]: any} = {};

  popupStatusMsg: any;
  errorMsg: boolean;

  currentPage = 1;
  offset = 0;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;
  itemsPerPage: any;
  size: any;
  allCheckedSerialNumbers = [];

  constructor(
    private modalService: ModalService,
    private fotaService: FotaService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.params.subscribe( stream => {
      this.paramData = stream;
      if (this.paramData.name && this.paramData.name !== '') {
        this.queryParam['name'] = this.paramData.name;
      }
      if (this.paramData.MODEL_CODE && this.paramData.MODEL_CODE !== '') {
        this.queryParam['modelCode'] = this.paramData.MODEL_CODE;
      }
      // if (this.paramData.FIRMWARE !== '') {
      //   this.queryParam['FIRMWARE'] = this.paramData.FIRMWARE;
      // }
      if (this.paramData.PROTOCOL && this.paramData.PROTOCOL !== '') {
        this.queryParam['protocol'] = this.paramData.PROTOCOL;
      }
      if (this.paramData.CUSTOMER_CODE && this.paramData.CUSTOMER_CODE !== '') {
        this.queryParam['customerCode'] = this.paramData.CUSTOMER_CODE;
      }

    });

    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 220; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page

    this.getDevicesList(this.queryParam);
    // this.deviceList = [{'model' : '123'}, {'model' : '1223'}];

  }

  private getDevicesList(params) {
    this.resLoader = true;
    params['offset'] = this.offset;
    params['size'] = this.itemsPerPage;
    this.fotaService.getDevicesListData(params)
      .pipe(first())
      .subscribe(
        data => {
          this.totalItems = data.count;
          const devices = data.deviceModels.map(item => {
            if (this.allCheckedSerialNumbers.indexOf(item.serialNumber) > -1) {
              item.checked = true;
            }
            return item;
          });
          this.deviceList = devices;
          this.resLoader = false;
        },
        error => {
          this.resLoader = false;
          console.log(error.error.message);
        }
      );
  }

  chooseDevices() {
    this.resLoader = true;

    if (this.queryParam.modelCode && this.queryParam.modelCode !== '') {
      this.selectedParam['MODEL_CODE'] = this.queryParam.modelCode;
    }
    if (this.queryParam.protocol && this.queryParam.protocol !== '') {
      this.selectedParam['PROTOCOL'] = this.queryParam.protocol;
    }
    if (this.queryParam.customerCode && this.queryParam.customerCode !== '') {
      this.selectedParam['CUSTOMER_CODE'] = this.queryParam.customerCode;
    }
    this.updateCheckedSerialNumbers();
    const formData = {
        'name' : this.queryParam.name,
        'criteria' : this.selectedParam,
        'selectedDevices' : this.allCheckedSerialNumbers
    };
    if (this.allCheckedSerialNumbers && this.allCheckedSerialNumbers.length > 0) {
      this.fotaService.chooseDevices(formData)
      .pipe(first())
      .subscribe(
        data => {
          this.resLoader = false;
          this.errorMsg = false;
          this.popupStatusMsg = 'Device group created successfully';
          this.router.navigate(['/viewDeviceGroups']);
          this.modalService.open('createGroupPopup');
        },
        error => {
          this.resLoader =  false;
          this.errorMsg = true;
          this.popupStatusMsg = error.error.description ? error.error.description : error.error.message;
          this.modalService.open('createGroupPopup');
        }
      );
    } else {
      this.resLoader =  false;
      this.errorMsg = true;
      this.popupStatusMsg = 'Please select atleast 1 device to proceed';
      this.modalService.open('createGroupPopup');
      // this.location.back();
      // setTimeout(() => {
      //   // reset local storage
      //   this.statusMsg = '';
      //   this.router.navigate(['/history']);
      // }, 2000);
    }
  }

  updateCheckedSerialNumbers() {
    const checkedSerialNumbers = this.deviceList
      .filter(item => item.checked)
      .map(item => item.serialNumber);
    this.allCheckedSerialNumbers = this.allCheckedSerialNumbers.concat(checkedSerialNumbers);
    this.allCheckedSerialNumbers = Array.from(new Set(this.allCheckedSerialNumbers));
  }

   // trigger when user change page
   onPageChange(page: number) {
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;
    this.updateCheckedSerialNumbers();
    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.offset = this.offset * this.itemsPerPage;
    this.deviceList = [];

    this.getDevicesList(this.queryParam); // API call
  }

  toSetColFilters(evt: any, searchType: any) {
    // console.log('toSetColFilters');
    const filterVal = evt.target.value.trim();
    console.log(filterVal, searchType);
    if (searchType === 'deviceIdFilter') {
      if (filterVal !== '') {
        this.queryParam.deviceId = filterVal;
      } else {
        delete this.queryParam.deviceId;
      }
    }
    if (searchType === 'imeiNumberFilter') {
      if (filterVal !== '') {
        this.queryParam.imeiNumber = filterVal;
      } else {
        delete this.queryParam.imeiNumber;
      }
    }
    // this.getReplcmntRequestData(this.paramReplcmntData);
  }
  toApplyColFilters() {
    this.deviceList = [];
    // console.log('toApplyColFilter');
    this.getDevicesList(this.queryParam);
  }
  closeModal(action: any) {
    this.modalService.close('createGroupPopup');
    // this.location.back();
  }

  back() {
    this.location.back();
  }
}
