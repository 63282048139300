import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FotaService {
    constructor(private http: HttpClient) { }

    // private messageSource = new BehaviorSubject('default message');
    // currentMessage = this.messageSource.asObservable();

    getDealerGroupsListPaginated(data: {}) {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/group`,
        {
            params: data
        });
    }

    getDealerGroupsList() {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/group` );
    }

    getDevicesListData(data: {}) {
        return this.http.get<any>(
          `${environment.apiUrl}/fm/fota/device/group`,
        {
            params: data
        });
    }

    createGroupData(formData: {}) {
        return this.http.post<any>(`${environment.apiUrl}/fm/fota/group`, formData );
    }

    chooseDevices(formData: {}) {
        return this.http.post<any>(`${environment.apiUrl}/fm/fota/group`, formData );
    }

    createFirmware(version, type, formData: {}) {
        return this.http.post<any>(`${environment.apiUrl}/fm/fota/firmware/upload/` + version + '/' + type, formData );
    }

    getFirmwares() {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/firmware`);
    }

    getFirmwaresFilter(data) {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/firmware`,
        {
            params: data
        });
        // return this.http.get<any>(`${environment.apiUrl}/fm/fota/firmware`);
    }

    getFotaHistory(data: {}) {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/history?otaType=FOTA`,
        {
            params: data
        });
    }

    createFota(formData: {}) {
        return this.http.put<any>(`${environment.apiUrl}/fm/fota/c2d/`, formData );
    }

    getFotaDevicesDetail(id, data: {}) {
        return this.http.get<any>(`${environment.apiUrl}/fm/fota/history/device/` + id,
        {
            params: data
        });
    }


}

