import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Md5 } from 'md5-typescript';

import { Observable, fromEvent, merge, of } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';

import { AuthService, ModalService } from '../../_services';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  online$: Observable<boolean>;
  name: string;

  loginForm: FormGroup;
  resLoader = false;
  submitted = false;
  errorMsg: string;
  grantType: string;
  hashPass : string;
  frmData: {};

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authentication: AuthService,
    private modalService: ModalService

  ) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
    this.networkStatus();
  }

  public networkStatus() {
    this.online$.subscribe(value => {
      this.name = `${value}`;
    })
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group(
      {
        userid: ['', [Validators.required, Validators.minLength(4), Validators.pattern(/^\S(.)+\S$/)]],
        password: ['', Validators.required]
      }
    );
    // reset login status
    //console.log(this.authentication.isUserlogIn);
    if(this.authentication.isUserlogIn){
      this.router.navigate(['/']);
    }
  }

  // easy to use getter for form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    if(this.name != 'true'){
      this.errorMsg = 'There is no active internet connection. Please enable your internet';
      return;
    }

    this.resLoader = true;
    this.errorMsg = '';
    this.grantType = 'password';
    this.hashPass = Md5.init(this.f.password.value);

    this.frmData = {
      username : this.f.userid.value,
      password : this.hashPass,
      grant_type :'password'
    }

    this.authentication.login(this.frmData)
      .pipe(first())
        .subscribe(
          data => {
            // console.log(data)
            this.router.navigate(['/']);
          },
          error => {
            // console.log(error.error_description);
            this.errorMsg = error.error.error_description;
            this.successModal();
            this.resLoader = false;
          }
        );
  }

  successModal() {
    this.modalService.open('successModal');

  }

  closesuccessModal() {
    this.modalService.close('successModal');

  }

}
