import {Injectable} from '@angular/core';

// function window(): any {
//   // return the native window obj
//   return window;
// }

@Injectable()
export class WindowRef {
    constructor() {}
    get nativeWindow(): any {
        return window;
    }
}
