import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { CotaService, FotaService } from './../_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { componentNeedsResolution } from '@angular/core/src/metadata/resource_loading';
@Component({
  selector: 'app-cota-details',
  templateUrl: './cota-details.component.html',
  styleUrls: ['./cota-details.component.css']
})
export class CotaDetailsComponent implements OnInit {

  vehicleGroups: any = [];
  selectedVehicleGroupId: any;

  commandType = ['SET', 'GET', 'CLEAR'];
  selectedCommandType: any;

  fotaConfigForm: FormGroup;
  fotaConfigFormData: any = {};
  fotaConfigFormSubmit = false;

  commandList: any = [];
  getArrList: any = [];
  selectedCommand: number;
  currCommandList: any = [];

  keyVauePairEle: any = [];

  statusMsg: string = '';
  statusFlag: boolean = false;

  disableAddBtn: boolean = true;
  cotaList = [];
  resLoader: boolean;
  showSetElement = false;
  showGetElement = false;

  currentPage = 1;
  offset = 0;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;
  itemsPerPage: any;
  size: any;

  constructor(
    private fb: FormBuilder,
    private cotaService: CotaService,
    private fotaService: FotaService,
    private router: Router,
    private location: Location
    ) {
      // this.selectedVehicleGroupId = '';
    }

  ngOnInit() {
    if (this.cotaService.commandList <= 0) {
      this.getCommandList('SET');
    }
    if (this.getArrList <= 0) {
      this.getCommandList('GET');
    }
    this.getDealerGroups();
    this.fotaConfigForm = this.fb.group({
      selectedGroup: ['', [Validators.required]],
      selectedCmdType: ['', [Validators.required]],
      selectedCommands: this.fb.array([this.fb.group({selectedCommandName: '', selectedCommandVal: ''})], [Validators.required])
    });

    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page

    this.currCommandList = this.selectedCommandList.value;
    // this.getCommandList();
    this.getCotaDetails();
  }

  get selectedCommandList() {
    return this.fotaConfigForm.get('selectedCommands') as FormArray;
  }

  private getCommandList(type) {
    this.cotaService.getCommandsList(type)
      .pipe(first())
      .subscribe(
        data => {
          if (type === 'SET') {
            this.cotaService.commandList = data;
          } else {
            this.getArrList = data;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  private getDealerGroups() {
    this.fotaService.getDealerGroupsList()
      .pipe(first())
      .subscribe(
        data => {
          this.vehicleGroups = data.deviceGroups;
        },
        error => {
          console.log(error);
        }
      );
  }

  onSelectVehicleGroup(selectedGroupId: any) {
    this.statusMsg = '';
    this.statusFlag = true;
    this.selectedVehicleGroupId = selectedGroupId;
    this.fotaConfigForm.patchValue({
      'selectedGroup': selectedGroupId
    });
  }
  onSelectCommandType(selectedType: any) {
    this.selectedCommandType = selectedType.value;
    this.statusFlag = false;
    this.statusMsg = '';
    if (selectedType.value === 'SET') {
        this.showSetElement = true;
        this.showGetElement = false;
    } else if (selectedType.value === 'GET') {
      this.showGetElement = true;
      this.showSetElement = false;
    } else if (selectedType.value === 'CLEAR') {
      this.showSetElement = false;
      this.showGetElement = false;
    }
    this.fotaConfigForm.patchValue({
      'selectedCmdType': selectedType.value
    });
  }

  onAddKeyValueEle() {
    const currCommand = this.fotaConfigForm.value.selectedCommands;
    // tslint:disable-next-line:max-line-length
    if (currCommand[currCommand.length - 1]['selectedCommandVal'] !== '' && currCommand[currCommand.length - 1]['selectedCommandName'] !== '') {
      if (this.selectedCommandType === 'SET') {
        this.selectedCommandList.push(this.fb.group({selectedCommandName: '', selectedCommandVal: ''}));
        this.statusMsg = '';
        this.statusFlag = false;
      }
    } else if (currCommand[currCommand.length - 1]['selectedCommandName'] === '') {
      this.statusMsg = 'Select a command';
      this.statusFlag = true;
    } else if (currCommand[currCommand.length - 1]['selectedCommandVal'] === '') {
      if (this.selectedCommandType === 'SET') {
        this.statusMsg = 'Command value should not empty';
        this.statusFlag = true;
      } else {
        this.commandList.statusFlag  = false;
      }
    }

    this.currCommandList = this.selectedCommandList.value;
    return false;
  }
  onAddGetValue() {
    const currCommand = this.fotaConfigForm.value.selectedCommands;
    // tslint:disable-next-line:max-line-length
    if (currCommand[currCommand.length - 1]['selectedCommandName'] !== '') {
       if (this.selectedCommandType === 'GET') {
        this.selectedCommandList.push(this.fb.group({selectedCommandName: ''}));
        this.statusMsg = '';
        this.statusFlag = false;
      }
    } else if (currCommand[currCommand.length - 1]['selectedCommandName'] === '') {
      this.statusMsg = 'Select a command';
      this.statusFlag = true;
    }
    this.currCommandList = this.selectedCommandList.value;
    return false;
  }

  onDelKeyValueEle(index: any) {
    this.currCommandList = this.selectedCommandList.value;
    if (this.currCommandList.length > 1) {
      this.selectedCommandList.removeAt(index);
      this.statusMsg = '';
      this.statusFlag = true;
    } else {
      this.statusMsg = 'Atleast 1 command should be selected';
      this.statusFlag = true;
    }
    return false;
  }

  onSelectCommand(data: any) {
    const currCommandName = data.value;
    let  currCommandMatchFlag = false;
    this.currCommandList.forEach((item: any, index: any) => {
      if (item.selectedCommandName ===  currCommandName) {
        currCommandMatchFlag = true;
      }
    });
    if (currCommandMatchFlag) {
      this.statusMsg = 'This Command already configure';
      this.statusFlag = true;
      this.disableAddBtn = true;
    } else {
      this.statusMsg = '';
      this.statusFlag = false;
      this.disableAddBtn = false;
    }
    this.currCommandList = this.selectedCommandList.value;
    // this.selectedCommand = data.value;
    // this.selectedCommandList.push(this.fb.group({selectedCommandName:'',selectedCommandVal:''}));
    // this.fotaConfigForm = this.fb.group({
    //   selectedGroup : this.selectedVehicleGroupId
    // });
  }

  onConfirmConfigBtn() {
    if (!this.fotaConfigForm.value.selectedGroup.value) {
      this.statusMsg = 'Please select a group';
      this.statusFlag = true;
      return;
    }
    if (!this.fotaConfigForm.value.selectedCmdType || this.fotaConfigForm.value.selectedCmdType === '') {
      this.statusMsg = 'Please select a command type';
      this.statusFlag = true;
      return;
    }

    if (this.selectedCommandType === 'SET') {
      const allCommandList = this.fotaConfigForm.value.selectedCommands;
      // tslint:disable-next-line:max-line-length
      if (allCommandList[allCommandList.length - 1]['selectedCommandVal'] === '' || allCommandList[allCommandList.length - 1]['selectedCommandName'] === '') {
        this.statusMsg = 'Please choose value for all command';
        this.statusFlag = true;
        return;
      }
      if (this.fotaConfigForm.invalid) {
        return;
      }
    } else if (this.selectedCommandType === 'GET') {
      const allCommandList = this.fotaConfigForm.value.selectedCommands;
      if (allCommandList[allCommandList.length - 1]['selectedCommandName'] === '') {
        this.statusMsg = 'Please choose atleast one command';
        this.statusFlag = true;
        return;
      }
      if (this.fotaConfigForm.invalid) {
        return;
      }
    }


    this.fotaConfigFormSubmit = true;
    this.fotaConfigFormData.groupCode = this.fotaConfigForm.value.selectedGroup.value;
    this.fotaConfigFormData.commandType = this.selectedCommandType;
    if (this.selectedCommandType === 'SET') {
      this.fotaConfigFormData.commands = {};
      this.selectedCommandList.value.forEach((item: any, index: any) => {
        this.fotaConfigFormData.commands[item.selectedCommandName] = item.selectedCommandVal;
      });
    } if (this.selectedCommandType === 'GET') {
      this.fotaConfigFormData.commands = {};
      this.selectedCommandList.value.forEach((item: any, index: any) => {
        this.fotaConfigFormData.commands[item.selectedCommandName] = '';
      });
    } else  if (this.selectedCommandType === 'CLEAR') {
      delete this.fotaConfigFormData.commands;
    }

    this.cotaService.addConfigDetail(this.fotaConfigFormData)
      .pipe(first())
      .subscribe(
        data => {
          this.statusMsg = 'Configuration successfully saved';
          this.statusFlag = false;
          setTimeout(() => {
            // reset local storage
            location.reload();
          }, 2000);
        },
        error => {
          console.log(error);
          if (error) {
            this.statusMsg = error.error.description ? error.error.description : error.error.message;
          } else {
            this.statusMsg = 'Something went wrong, please try again.';
          }
          this.statusFlag = true;
        }
      );
    }

    compareObjects(o1: any, o2: any): boolean {
      return o1.groupCode === o2.groupCode && o1.name === o2.name;
    }

    compareCommondObjects(o1: any, o2: any): boolean {
      return o1.param === o2.param && o1.name === o2.name;
    }

    private getCotaDetails() {
      this.resLoader = true;
      const formData = {
        offset: this.offset,
        size: this.itemsPerPage
      };
      this.cotaService.getCotaDetails(formData)
        .pipe(first())
        .subscribe(
          data => {
            this.totalItems = data.count;
            this.cotaList = data.otaInvocationModels;
            this.resLoader = false;
          },
          error => {
            this.resLoader = false;
            console.log(error);
          }
        );
    }

    // trigger when user change page

    onPageChange(page: number) {
      // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;
        this.resLoader = true;
        this.currentPage = page;
        this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
        this.offset = this.offset * this.itemsPerPage;
        this.cotaList = [];
        this.getCotaDetails(); // API call
      }

}
