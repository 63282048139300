import { Component, OnInit } from '@angular/core';
import {  ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { FotaService } from './../_services/fota.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-device-group',
  templateUrl: './device-group.component.html',
  styleUrls: ['./device-group.component.css']
})
export class DeviceGroupComponent implements OnInit {

  deviceList = [];
  resLoader: boolean;
  groupCode: {};

  currentPage = 1;
  offset = 0;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;
  itemsPerPage: any;
  size: any;

  constructor(
    private fotaService: FotaService,
    private route: ActivatedRoute,
    private location: Location

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.groupCode = params;
    });
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page

    this.getDevicesList(this.groupCode);

  }

  back() {
    this.location.back();

  }

  private getDevicesList(code) {
    this.resLoader = true;
    const formData = {
      offset: this.offset,
      size: this.itemsPerPage,
      groupCode: code.groupCode
    };
    this.fotaService.getDevicesListData(formData)
      .pipe(first())
      .subscribe(
        data => {
          this.totalItems = data.count;
          this.deviceList = data.deviceModels;
          this.resLoader = false;
        },
        error => {
          console.log(error);
        }
      );
  }
   // trigger when user change page
   onPageChange(page: number) {
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.offset = this.offset * this.itemsPerPage;
    // this.deviceList = [];

    this.getDevicesList(this.groupCode); // API call
  }
}
