import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent{
  @Input() userAccessData:any = {};

  public href: string = "";

  constructor(
    private router: Router
  ) {
    this.href = this.router.url;
  }

  ngOnInit() {
    console.log(this.userAccessData.sub1List)
  }

}
