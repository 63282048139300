import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RegisterUserService {

  constructor(private http: HttpClient) { }


  createUser(data: any) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer 60b812b1-6200-48ff-afe8-c5ecc6a3084b'
    //   })
    // }
    return this.http.post<any>(`${environment.apiV2Url}/toyota/user`, data).toPromise();
  }

  resendOtp(data: any) {
    return this.http.post<any>(`${environment.apiV2Url}/toyota/sms`, data).toPromise();
  }
}
