import { Injectable } from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class CustomValidatorService {

  static MatchPassword(control: AbstractControl) {
    let password = control.get('utrNo').value; // to get value in input tag
    let confirmPassword = control.get('utrNoConfirm').value; // to get value in input tag
    if(password != confirmPassword) {
      //console.log('false');
      control.get('utrNoConfirm').setErrors( {MatchPassword: true} )
    } else {
      control.get('utrNoConfirm').setErrors( null );
      //console.log('true');
      return null
    }
  }

  static MatchPass(control: AbstractControl) {
    let password = control.get('newPass').value; // to get value in input tag
    let confirmPassword = control.get('confirmPass').value; // to get value in input tag
    if(password != confirmPassword) {
      //console.log('false');
      control.get('confirmPass').setErrors( {MatchPassword: true} )
    } else {
      control.get('confirmPass').setErrors( null );
      //console.log('true');
      return null
    }
  }

  static validateEmailid(control: AbstractControl) {
    //console.log(control.get('emailId').value);
    let emailId = control.get('emailId').value; // to get value in input tag
    //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /^\b(?!.{10})(([a-zA-Z\-0-9_.]+(\.[a-zA-Z\-0-9_.]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\b$/;
    if(emailId != ''){
      if(!re.test(emailId)) {
        // console.log('true');

      }
      else {
        // console.log('false');
        control.get('emailId').setErrors( null );
        return null
      }
    }
   }

}



