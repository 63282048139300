import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers/';
import { AuthService, ModalService, TitleService, ResourceAccessService, InterceptService } from './_services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './_guards/auth.guard';
import {NgxPaginationModule} from 'ngx-pagination';

//import { fakeBackendProvider } from './_helpers';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';

import { LoginComponent } from './auth/login/login.component';
import { HeaderComponent } from './_layout/header/header.component';
import { SidenavComponent } from './_layout/sidenav/sidenav.component';
import { WebLayoutComponent } from './_layout/web-layout/web-layout.component';
import { HomeComponent } from './home/home.component';
import { RequestNotFoundComponent } from './request-not-found/request-not-found.component';
import { DealerComponent, ViewDealerComponent } from './dealer';
import { OrderComponent, PlaceOrderComponent} from './order';
import { HistoryComponent, ViewHistoryComponent} from './history';
import { ModalComponent } from './misc/modal/modal.component';
import {WindowRef} from './_helpers/window-ref';
import { KycDetailsComponent } from './kyc-details/kyc-details.component';
import { KycAddComponent } from './kyc-add/kyc-add.component';
import { KycUploadComponent } from './kyc-upload/kyc-upload.component';
import { DealerGroupsComponent } from './dealer-groups/dealer-groups.component';
import { DeviceGroupComponent } from './device-group/device-group.component';
import { FirmwareComponent } from './firmware/firmware.component';
import { FotaStatusComponent } from './fota-status/fota-status.component';
import { FotaDetailsComponent } from './fota-details/fota-details.component';
import { CotaDetailsComponent } from './cota-details/cota-details.component';
import { ChooseDevicesComponent } from './choose-devices/choose-devices.component';
import { CotaCommandsComponent } from './cota-commands/cota-commands.component';
import { RegisterVehicleComponent } from './register-vehicle/register-vehicle.component';
import { ForgotPassComponent } from './auth/forgot-pass/forgot-pass.component';
import { ChangePassComponent } from './auth/change-pass/change-pass.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidenavComponent,
    WebLayoutComponent,
    HomeComponent,
    RequestNotFoundComponent,
    OrderComponent,
    PlaceOrderComponent,
    DealerComponent,
    ViewDealerComponent,
    HistoryComponent,
    ViewHistoryComponent,
    ModalComponent,
    KycDetailsComponent,
    KycAddComponent,
    KycUploadComponent,
    DealerGroupsComponent,
    DeviceGroupComponent,
    FirmwareComponent,
    FotaStatusComponent,
    FotaDetailsComponent,
    CotaDetailsComponent,
    ChooseDevicesComponent,
    CotaCommandsComponent,
    RegisterVehicleComponent,
    ForgotPassComponent,
    ChangePassComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatDatepickerModule,
    MatStepperModule,
    MatDialogModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  providers: [
    AuthService,
    ModalService,
    TitleService,
    ResourceAccessService,
    InterceptService,
    AuthGuard,
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
