import { Component, OnInit, Input } from '@angular/core';
import {  ModalService } from '../_services';
import { first } from 'rxjs/operators';
import { FotaService } from './../_services/fota.service';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-dealer-groups',
  templateUrl: './dealer-groups.component.html',
  styleUrls: ['./dealer-groups.component.css']
})
export class DealerGroupsComponent implements OnInit {

  dealerGroupsData = [];
  resLoader: boolean;
  createGroupForm: FormGroup;
  formData: any = { };
  // paramData = {};
  paramData: {[k: string]: any} = {};
  popupStatusMsg = '';
  submitted = false;
  protocols = ['MQTT'];
  selectedProtocol = '';
  paramDealerGroupsData: any = {};

  currentPage = 1;
  offset = 0;
  size = 10;
  totalItems: number;
  rightVwHeight: number;
  userTblHeight: number;
  selectedDeviceModel: '';

  @Input() queryParams;
  itemsPerPage: any;
  clicked = false;
  // size: number;
  // size = 10;


  constructor(
    private modalService: ModalService,
    private fotaService: FotaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.selectedProtocol = 'MQTT';
    this.createGroupForm = this.formBuilder.group({
      groupName: ['', [Validators.required, Validators.pattern(/^\b(.)+\b$/)]],
      selectedDeviceModel: ['', Validators.required],
      selectedProtocol: ['MQTT'],
      customerCode: ['BAJAJ']
    });
    this.rightVwHeight = document.getElementById('rightSideContent').offsetHeight; // right side content height
    this.userTblHeight = this.rightVwHeight - 185; // get user list table height
    this.itemsPerPage = Math.round(this.userTblHeight / 38) - 1; // calculate size of user list paer page

    this.getDealerGroups();
  }

  get f() { return this.createGroupForm.controls; }

  onChangeAction(data) {
    if (data.value === 'createGroupPopup') {
      // this.addBatterySetForm.reset();
      this.modalService.open('createGroupPopup');
    }
  }

  onSelectProtocol(data) {
    this.selectedProtocol = data.value;
  }
  onSelectDeviceModel(data) {
    this.selectedDeviceModel = data.value;
  }

  closeModal(id: string) {
    this.createGroupForm.patchValue({
      groupName: '',
      selectedDeviceModel: ''
    });

    // this.createGroupForm.reset();
    this.submitted = false;
    this.clicked = false;
    // this.selectedProtocol = '';
    this.popupStatusMsg = '';
    this.modalService.close(id);
  }

  isEmptyData(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  createGroup(val) {
    this.clicked = true;
    this.submitted = true;
    this.popupStatusMsg = '';

    if (this.createGroupForm.invalid) {
      return;
    }

    if (this.f.groupName.value && (this.f.groupName.value.trim() !== '' || this.f.groupName !== null)) {
      this.formData.name = this.f.groupName.value.trim();
    } else {
      this.formData.name = null;
    }

    if (this.f.selectedDeviceModel.value && (this.f.selectedDeviceModel.value.trim() !== '' || this.f.selectedDeviceModel !== null)) {
      this.paramData['MODEL_CODE'] = this.f.selectedDeviceModel.value.trim();
    } else {
      this.paramData['MODEL_CODE'] = null;
    }
    // if (this.f.firmware.value.trim() !== '') {
    //   this.paramData['FIRMWARE'] = this.f.firmware.value.trim();
    // }
    if (this.selectedProtocol && (this.selectedProtocol !== '' || this.selectedProtocol !== null)) {
      // this.paramData['PROTOCOL'] = this.selectedProtocol;
      this.paramData['PROTOCOL'] = 'MQTT';
    } else {
      this.paramData['PROTOCOL'] = null;
    }
    if (this.f.customerCode.value && (this.f.customerCode.value.trim() !== '' || this.f.customerCode !== null)) {
      // this.paramData['CUSTOMER_CODE'] = this.f.customerCode.value.trim();
      this.paramData['CUSTOMER_CODE'] = 'BAJAJ';
    } else {
      this.paramData['CUSTOMER_CODE'] = null;
    }

    // if (this.isEmptyData(this.paramData)) {
    //   this.popupStatusMsg = 'Please enter Device or Firmware or Protocol or Code';
    //   return;
    // }
    this.formData. criteria = this.paramData;
    this.queryParams = this.paramData;
    // tslint:disable-next-line:no-unused-expression

    this.resLoader = true;
    if (val === 'group') {
      this.resLoader = false;
      this.fotaService.createGroupData(this.formData)
      .pipe(first())
      .subscribe(
        data => {
          this.resLoader = false;
          this.closeModal('createGroupPopup');
          // this.getDealerGroups();
          location.reload();
        },
        error => {
          this.clicked = false;
          this.popupStatusMsg = error.error.description;
          console.log(error.error.description);
        }
      );
    } else if (val === 'device') {
      const urlString: any = {};
      if (this.formData['name'] && this.formData['name'] !== null) {
        urlString.name = this.formData['name'];
      }
      if (this.formData['criteria']['MODEL_CODE'] && this.formData['criteria']['MODEL_CODE'] !== null) {
        urlString.MODEL_CODE = this.formData['criteria']['MODEL_CODE'];
      }
      if (this.formData['criteria']['PROTOCOL'] && this.formData['criteria']['PROTOCOL'] !== null) {
        // urlString.PROTOCOL = this.formData['criteria']['PROTOCOL'];
        urlString.PROTOCOL = 'MQTT';
      }
      if (this.formData['criteria']['CUSTOMER_CODE'] && this.formData['criteria']['CUSTOMER_CODE'] !== null) {
        // urlString.CUSTOMER_CODE = this.formData['criteria']['CUSTOMER_CODE'];
        urlString.CUSTOMER_CODE = 'BAJAJ';
      }

      const modal_code = '';
      this.resLoader = false;
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/chooseDevices', urlString]);
    }
  }


  private getDealerGroups() {
    this.resLoader = true;
    // const formData = {
    //   offset: this.offset,
    //   size: this.size
    // };
    this.paramDealerGroupsData.offset = this.offset;
    this.paramDealerGroupsData.size = this.size;

    this.fotaService.getDealerGroupsListPaginated(this.paramDealerGroupsData)
      .pipe(first())
      .subscribe(
        data => {
          this.totalItems = data.count;
          this.dealerGroupsData = data.deviceGroups;
          this.resLoader = false;
        },
        error => {
          console.log(error);
        }
      );
  }

  toApplyDepttGroupFilters() {
    this.dealerGroupsData = [];
    // console.log('toApplyColFilter');
    this.getDealerGroups();
  }
  toSetDepttGroupFilters(evt: any, searchType: any) {
    // console.log('toSetColFilters');
    const filterVal = evt.target.value.trim();
    console.log(filterVal, searchType);
    if (searchType === 'depttGroupFilters') {
      if (filterVal !== '') {
        this.paramDealerGroupsData.name = filterVal;
      } else {
        delete this.paramDealerGroupsData.name;
      }
    }
    // this.getAllFirmwares(this.paramFirmwareData);
  }

  // trigger when user change page
  onPageChange(page: number) {
    // this.itemsPerPage = this.itemsPerPage > 10 ? this.itemsPerPage : 10;

    this.resLoader = true;
    this.currentPage = page;
    this.offset =  page === 1 ? 0 : page - 1; // offset should be less than curr page to handle offset 0
    this.offset = this.offset * this.itemsPerPage;
    // this.dealerGroupsData = [];

    this.getDealerGroups(); // API call
  }
}
